
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Spinner

} from "@chakra-ui/react";


import { NavLink, useLocation } from "react-router-dom";
// Assets
import basic from "assets/img/login.jpg";
import React from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { Redirect } from "react-router-dom";
import { useState } from "react";
import { Auth } from 'aws-amplify';


function SignInClient() {

  // Auth variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(false);

  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state



  const titleColor = useColorModeValue("blue.400", "blue.400");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");
  



  async function signIn() {
    var username = email;
    try {
      setIsLoading(true); // Set isLoading to true on sign in button click
      const user = await Auth.signIn(username, password);
      console.log(user)
      setStatus(true)
    } catch (error) {
      //setError(error);
      setError(error.message)
      console.log('error signing in', error);
    } finally {
      setIsLoading(false); // Set isLoading to false on successful sign in or error
    }
  }
  
  return (
    
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      {status == true ? <Redirect from={`/`} to="/admin/dashboard/default" /> : 
      <>
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "70vh" }}
        w={{ md: "calc(100vw - 0px)" }}
        borderRadius={{ md: "0px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={basic}
        
        bgPosition="center"
        bgSize="cover"
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="120px"
      >
        
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Text
            fontSize="xl"
            color={textColor}
            fontWeight="bold"
            textAlign="center"
            mb="22px"
          >
            Sign In
          </Text>
          
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email
            </FormLabel>
            <Input
              id="email-field"
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="email"
              placeholder="Your email address"
              mb="24px"
              size="lg"
              onChange={(d) => {setEmail(d.target.value)}}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              id="password-field"
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="password"
              placeholder="Your password"
              mb="24px"
              size="lg"
              onChange={(d) => {setPassword(d.target.value)}}
            />
            {error && (
              <Flex w='100%' justifyContent={'center'} alignItems={'center'}>
              <Text color="red.500" fontSize="sm" paddingBottom={5}>
                {error}
              </Text>
              </Flex>
            )}
            <Button
              type="submit"
              fontSize="sm"
              fontWeight="bold"
              colorScheme={'blue'}
              w="100%"
              h="45"
              mb="24px"
              onClick={()=>{signIn()}}
              isDisabled={!email || !password}
              isLoading={isLoading} // Add isLoading prop to show loading indicator
            >
              {isLoading ? <Spinner size="sm" /> : "SIGN IN"} 
            </Button>

          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColor} fontWeight="medium">
              Need have an account?
              <NavLink to="/auth/authentication/sign-up/client">
                <Link 
                  color={titleColor}
                  as="span"
                  ms="5px"
                  href={`${process.env.PUBLIC_URL}/#/auth/authentication/sign-in/client`}
                  fontWeight="bold"
                >
                  Sign Up
                </Link>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
      </>}
    </Flex>
  );

}
  

export default SignInClient;


