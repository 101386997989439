import React, { useState, useEffect, useRef } from 'react';
import { Flex, Input, Button,Box, Textarea, Text,Image } from "@chakra-ui/react";
import { BsCursorFill , BsPaperclip, BsPlusLg, BsFillEmojiLaughingFill,BsArrowReturnLeft,BsXCircle,BsFillCloudArrowDownFill } from "react-icons/bs";
import { Icon } from '@chakra-ui/react'
import EmojiPicker from 'emoji-picker-react';
import ResizeTextarea from "react-textarea-autosize";


const MessageInput = ({ inputMessage, setInputMessage, handleSendMessage, handleKeyDown, handleFileSelect,reply,handleSetReply }) => {
	const [emoji, setEmoji] = useState(false);
	const ref = useRef()
	const [isReplyLink, setIsReplyLink] =useState(false);


	useEffect(() => {
		if (reply && /^(http|https):\/\/[^ "]+$/.test(reply.message)) {
			setIsReplyLink(true)
		}
	}, [reply]);

	const formatFileName = (fileName) => {
		fileName = decodeURIComponent(fileName);
		let firstSplit = fileName.split('/').pop()
		var final = firstSplit.split('.$plit.').pop()
		if (final.length < 15){
			var n = final
		} else {
			var n = final.substring(0, 15) + "..." + final.split('.').pop()
		}
		return n.toString()
	}

	
	
	
  return (
	<Flex flexDirection={'column'}>
		<Box position="relative">
        {emoji ? 
          <Box position="absolute" top="-400px" left="0" zIndex={1}>
            <EmojiPicker
              emojiStyle='twitter'
              height={400}
              onEmojiClick={(e) => {
                setInputMessage((m) => { return m + e.emoji });
                setEmoji(false);
              }}
            />
          </Box>
          : null}
	{reply ? 
	<Flex w="100%" mb="2" bg="#F1F1F1" justifyContent={'flex-start'} alignItems={'flex-start'} borderTopRadius={10} pb={0}>
		<Flex  justifyContent={'flex-start'} alignItems={'flex-start'} w="100%" maxW={'100%'}>
		<Flex  justifyContent={'flex-start'} alignItems={'flex-start'} w="7%" maxW={'7%'} mr={[5,5,2]}>
			<Icon 
				as={BsArrowReturnLeft}
				w="20px" 
				h="20px"
				m={2}
				ml={5}
				
			/>
		</Flex>
			<Flex flexDirection={'column'} pt={1} w="90%" maxW={'90%'} >
				<Flex flexDirection={'row'} justifyContent='space-between'>
					<Text as='b'>{reply ? reply.senderName : null}</Text>
					
					
				</Flex>
				<Flex flexDirection={'row'}>
					<Flex w={'2.3px'} backgroundColor='green' borderRadius={20} mr={2}></Flex>
					<Box flexDirection={'column'} alignItems={'center'} overflow='hidden' maxW={400}>
					{isReplyLink && reply.type == 'text' ?
								<a href={reply.message} target="_blank">{reply.message}</a>
								:
								reply.type !== 'png' && reply.type !== 'jpg' && reply.type !== 'jpeg' && reply.type !== 'text' ? (
								<Box as="a" href={reply.message} target="_blank" download display="flex" alignItems="center" justifyContent="center" p={4} borderWidth={1} rounded="md">
									<Box as={BsFillCloudArrowDownFill} size={20} />
									<Text ml={2}>{formatFileName(reply.message)}</Text>
								</Box>
								) : (
								reply.type === 'png' || reply.type === 'jpg' || reply.type === 'jpeg' ? (
									<Image
									src={reply.message}
									alt="image message"
									w={'30%'}
									h={'auto'}
									
									/>
								) : (
									<Text style={{ whiteSpace: 'pre-wrap' }}>{reply.message}</Text>
								)
								)}
					</Box>
				</Flex>
			</Flex>
			<Flex  justifyContent={'flex-end'} alignItems={'flex-end'} w="7%" maxW={'7%'} mr={2}>
			<Icon 
						as={BsXCircle}
						w="20px" 
						h="20px"
						m={2}
						ml={5}
						onClick={() => {handleSetReply(false)}}
						
					/>
			</Flex>
		</Flex>
	</Flex>
	: null}
		
	<Flex w="100%" mt="-2" bg="white" justifyContent={'center'} alignItems={'flex-end'} borderRadius={10} pb={0}>
		<Flex  justifyContent={'center'} alignItems={'center'} >
			<Icon 
				as={BsPlusLg} 
				color={"#888888"}
				w="20px" 
				h="20px" 
				ml={4}
				mb={2}
				onClick={() => {
					const fileInput = document.createElement('input');
					fileInput.type = 'file';
					fileInput.addEventListener('change', (event) => {
						handleFileSelect(event);
					});
					fileInput.click();
				}}
				/>

			
			<Icon 
				as={BsFillEmojiLaughingFill} 
				color={"#888888"}
				_hover={{ color: '#40BE04' }} 
				w="20px" 
				h="20px" 
				ml={4}
				mb={2}
				onClick={ () => {
					emoji ? setEmoji(false) : setEmoji(true)
				}}
				/>
			
			<Icon 
				as={BsPaperclip} 
				color={"#888888"}
				_hover={{ color: '#40BE04' }}
				w="20px" 
				h="20px" 
				ml={4}
				mb={2}
				onClick={() => {
					const fileInput = document.createElement('input');
					fileInput.type = 'file';
					fileInput.addEventListener('change', (event) => {
						handleFileSelect(event);
					});
					fileInput.click();
				}}
				/>
		</Flex>
			
			<Textarea
				minH="unset"
				overflow="hidden"
				w="100%"
				resize="none"
				mt={1}
				ref={ref}
				minRows={1}
				as={ResizeTextarea}
				placeholder="Type Something..."
				border="none"
				borderRadius="none"
				_focus={{
				border: "0px solid black",
				}}
				onKeyDown={handleKeyDown}
				onKeyPress={(e) => {
					if (e.key === "Enter" && !e.shiftKey) {
						e.preventDefault();
						handleSendMessage();
					}
					if (e.key === "Enter" && e.shiftKey) {
						e.preventDefault();
						setInputMessage((m) => { return m + "\n" });
					}
				}}
				
				value={inputMessage}
				onChange={(e) => setInputMessage(e.target.value)}
			/>
			<Button
				bg="#F7FAFC"
				borderRadius="none"
				_hover={{
				bg: "#F7FAFC",
				}}
				disabled={inputMessage.trim().length <= 0}
				onClick={handleSendMessage}
			>
					<Icon as={BsCursorFill} color={"#39BB02"} w="25px" h="25px" ml={4}/>
			</Button>
	</Flex>
	</Box>

	</Flex>
	
  );
};

export default MessageInput;