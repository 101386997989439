import React, { useRef, useState, useContext, useEffect } from "react";

import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Spinner
} from "@chakra-ui/react";
import { FaUpload } from "react-icons/fa";
import { SocketContext } from "../../../components/Layout/MainPanel";
import { useLocation } from "react-router-dom";


function ResumeUpload({selectedJob}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const socket = useContext(SocketContext);
  const [showAlert, setShowAlert] = useState(false);
  const [uploading, setUploading] = useState(false);

  
    
    useEffect(() => {
      socket.on("uploadSuccess", (data) => {
        setUploading(false)
        setSelectedFile(null);
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000); // remove alert after 5 seconds
      });
    }, [socket]);

    
  

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setSelectedFile(event.dataTransfer.files[0]);
  };

  const handleFileUpload = () => {
    setUploading(true);
    // Implement file upload logic here
    console.log("File uploaded:", selectedFile.name);
        const file = selectedFile;
        console.log(file);
        const chunkSize = 10 * 1024; // 10KB chunk size
        let offset = 0;
        const fileData = {
          name: file.name,
          type: file.type,
          size: file.size,
          data: null,
          'jobId':selectedJob._id,
          'timestamp':new Date()
        };
  
        while (offset < file.size) {
          const chunk = file.slice(offset, offset + chunkSize);
          socket.emit('sendResume', chunk, offset, file.size, fileData, selectedJob._id);
          offset += chunkSize;
        }
        
  
        
      
  };

  const isFileSelected = selectedFile !== null;

  return (
    <>
    
    <Box
      w="100%"
      p={8}
      borderRadius={10}
      border="2px solid"
      borderColor="blue.100"
      onDrop={handleFileDrop}
      onDragOver={(event) => event.preventDefault()}
    >
      <Flex direction="column" alignItems="center">
        <Box mb={4}>
          <Text fontSize="lg" fontWeight="bold" textAlign="center">
            Drag and drop your zip file here or click to select a file
          </Text>
        </Box>
        <Flex direction="column" alignItems="center">
          {isFileSelected ? (
            <Box mt={4} textAlign="center">
              <Text fontWeight="bold" mb={2}>
                Selected file:
              </Text>
              <Text>{selectedFile.name}</Text>
              <Button
                mt={4}
                colorScheme="blue"
                onClick={() => setSelectedFile(null)}
                mr={5}
              >
                Remove
              </Button>
              <Button
                mt={4}
                colorScheme="blue"
                onClick={handleFileUpload}
                isDisabled={!isFileSelected || uploading}
              >
                {uploading ? <Spinner size="sm" /> : 'Upload'}
              </Button>
              
            </Box>
          ) : (
            <InputGroup w="100%">
              <InputLeftElement
                pointerEvents="none"
              />
              <Button as="label" htmlFor="fileInput" colorScheme="blue">
              <Icon as={FaUpload} mr={2} /> Choose File
              </Button>
              <Input
                type="file"
                id="fileInput"
                accept=".zip, .pdf"
                onChange={handleFileSelect}
                display="none"
              />
            </InputGroup>
          )}
        </Flex>
      </Flex>
      
      
    </Box>
    <Flex justify={'center'} alignItems={'center'}>

{showAlert && (
       <Alert
       borderRadius="8px"
       status="success"
       variant="solid"
       mt={10}
       mb={-5}
       alignSelf='center'
       position="relative"
       animation="slide-up 0.3s ease-in-out"
     >
       <AlertIcon />
       <AlertTitle mr="12px">Success!</AlertTitle>
       <AlertDescription>Your resumes were uploaded succesfully.</AlertDescription>
       <CloseButton
         position="absolute"
         fontSize={{ sm: "8px", md: "12px" }}
         right={{ sm: "-4px", md: "8px" }}
         top={{ sm: "-4px", md: "8px" }}
         onClick={() => setShowAlert(false)}
       />
     </Alert>
      )}
    </Flex>
    </>
    
  );
}




export default ResumeUpload;