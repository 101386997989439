/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Box, Flex, Icon, Text, useColorModeValue, useColorMode } from "@chakra-ui/react";
import React from "react";

function TransactionRow(props) {
  const textColor = useColorModeValue("#00DBFF", "white");
  let bgColor = useColorModeValue("#EDF2F7", "#313843");
  const { name, stats, matches, } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex _hover={{ borderColor: "blue.300",borderWidth:"3px"}} w={'100%'} borderColor={bgColor} borderWidth="3px"
    cursor="pointer" style={{borderRadius:10,height:"75px",padding:"30px",margin:"5px",justifyContent:"flex-start",backgroundColor:bgColor}}>
      <Flex alignItems="center" w={'100%'}>
        
        <Flex direction="column" w={'100%'}>
          <Text
            fontSize={{ sm: "md", md: "lg", lg: "lg" }}
            fontWeight="bold"
          >
            {name}
          </Text>
          <Flex w={'100%'} flexDir={'row'} justifyContent={'flex-start'}>
              <Text
                fontSize={{ sm: "md", md: "md", lg: "md" }}
                color="gray.400"
                fontWeight="semibold"
                mt={1}
                mr={10}
              >
                Suitable Matches: {stats.highScoreResumes ? stats.highScoreResumes : 0 }
              </Text>
              <Text
                fontSize={{ sm: "md", md: "md", lg: "md" }}
                color="gray.400"
                fontWeight="semibold"
                mt={1}
                mr={10}
              >
                Processed Resumes: {stats.processedResumes ? stats.processedResumes : 0 }
              </Text>
              <Text
                fontSize={{ sm: "md", md: "md", lg: "md" }}
                color="gray.400"
                fontWeight="semibold"
                mt={1}
                mr={10}
              >
                Errored Resumes: {stats.errorResumes }
              </Text>
              
              <Text
                fontSize={{ sm: "md", md: "md", lg: "md" }}
                mt={1}
                mr={10}
                color="gray.400"
                fontWeight="semibold"
              >
                Uploaded Resumes: {stats.uploadedResumes }
              </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box
        color={
          matches > 0
            ? "green.400"
            : matches == 0
            ? "red.400"
            : { textColor }
        }
      >
        <Text fontSize={{ sm: "md", md: "lg", lg: "md" }} fontWeight="bold">
          {matches}
        </Text>
      </Box>
    </Flex>
  );
}

export default TransactionRow;
