/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Image,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  UnorderedList,
} from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import { NavLink } from "react-router-dom";

// Custom components
import Editor from "components/Editor/Editor";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import IconBox from "components/Icons/IconBox";
import { RocketIcon } from "components/Icons/Icons";
import React, { useRef, useState,useContext,useEffect } from "react";
import { AiFillSetting } from "react-icons/ai";
import { BsCircleFill, BsClockFill,BsXCircle} from "react-icons/bs";
import { FaCube } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { BsClock,BsClockHistory,BsPencilSquare } from "react-icons/bs";
import Select from 'react-select';
import { SocketContext } from '../../../components/Layout/MainPanel'
import Creatable from 'react-select/creatable';
import rezimation from '../../../assets/img/rezimation.gif'
import iconblue from '../../../assets/img/icon-blue.png'
import { Link } from 'react-router-dom';



function CreateJob() {
  const socket = useContext(SocketContext);
  const textColor = useColorModeValue("gray.700", "white");
  const bgPrevButton = useColorModeValue("gray.100", "gray.100");
  const iconColor = useColorModeValue("gray.300", "gray.700");
  const [activeBullets, setActiveBullets] = useState({
    import: true,
    details: false,
    requirements: false,
    review:false,
  });
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)

  const indeedDescriptionTab = useRef();
  const jobDetailsTab = useRef();
  const requirementsTab = useRef();
  const reviewTab = useRef();

  useEffect(() => {
    if (jobDetailsTab.current) {
    jobDetailsTab.current.click()
    setImporting("manually")
    }
  }, [jobDetailsTab.current]);

  

  const [newJob,setNewJob] = useState(
    {
      jobTitle: "",
      indeedDesc: "",
      indeedStripped:"",
      description:"",
      jobType:"Full Time",
      jobStyle:"Remote",
      jobState:"",
      skills:[{'skill': ""}, {'skill': ""}],
      education:[{'educationRequirements': ""}],
      responsibilities:[{'responsibility': ""},{'responsibility': ""}]
    }
  )

  const [savedJob, setSavedJob] = useState(undefined);
  const [importing,setImporting] = useState("manually");
  const [parsed, setParsed] = useState(false)
  const [parsing, setParsing] = useState(false)

  

  useEffect(() => {
      if (socket){
      socket.on('newJobParsed', (data) => {
        console.log(data)
        setNewJob(data)
        setParsed(true)
        setParsing(false)
        jobDetailsTab.current.click();
      });

      socket.on('newJobSaved', (data) => {
        setSaving(false);
        setSaved(true);
        setSavedJob(data)
        console.log(data)
        if (reviewTab.current !== null) {
          reviewTab.current.click();
        } else {
          console.log(reviewTab.current)
        }
      });
      
      }

  }, [socket]);

  const parseJobDesc = async () => {
    socket.emit('parseJob', 
    {
      jobDescription: newJob.indeedStripped,
      indeedDesc: newJob.indeedDesc
    });
    setParsing(true)
  };

  function stripHtml(html) {
    var doc = new DOMParser().parseFromString(html, 'text/html');
    
    setNewJob(prevJob => ({
      ...prevJob,
      indeedStripped: doc.body.textContent || ''
    }));
    console.log(doc.body.textContent)
  }

  const handleEduChange = (event, index) => {
    const newEdu = [...newJob.education];
    newEdu[index].educationRequirements = event.target.value;
    setNewJob(prevJob => ({
      ...prevJob,
      education: newEdu
    }));
  }

  const handleResChange = (event, index) => {
    const newRes = [...newJob.responsibilities];
    newRes[index].responsibility = event.target.value;
    setNewJob(prevJob => ({
      ...prevJob,
      responsibilities: newRes
    }));
  }
  

  const handleSkillChange = (event, index) => {
    const newSkills = [...newJob.skills];
    newSkills[index].skill = event.target.value;
    setNewJob(prevJob => ({
      ...prevJob,
      skills: newSkills
    }));
  }
  

  

  const handleAddNew = () => {
    setNewJob(prevJob => ({
      ...prevJob,
      skills: [...prevJob.skills, { skill: "", experience: "" }]
    }));
  };
  

  const handleAddNewEdu = () => {
    setNewJob(prevJob => ({
      ...prevJob,
      education: [...prevJob.education, { educationRequirement: "" }]
    }));
  };

  const handleAddNewRes = () => {
    setNewJob(prevJob => ({
      ...prevJob,
      responsibilities: [...prevJob.responsibilities, { responsibility: "" }]
    }));
  };
  

  const handleRemoveEdu = (index) => {
    setNewJob(prevJob => ({
      ...prevJob,
      education: prevJob.education.filter((_, i) => i !== index)
    }));
  };

  const handleRemoveRes = (index) => {
    setNewJob(prevJob => ({
      ...prevJob,
      responsibilities: prevJob.responsibilities.filter((_, i) => i !== index)
    }));
  };
  

  const handleRemove = (index) => {
    setNewJob(prevJob => ({
      ...prevJob,
      skills: prevJob.skills.filter((_, i) => i !== index)
    }));
  };
  

  const handleSaveJob = () => {
    socket.emit('saveJob', newJob)
  };

  
  
  
  


  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "125px", lg: "105px" }}
    >
      
      <Tabs variant="unstyled" mt="24px" display="flex" flexDirection="column">
        <TabList
          display="flex"
          align="center"
          alignSelf="center"
          justifySelf="center"
        >
          <Tab
            ref={indeedDescriptionTab}
            _focus="none"
            w={{ sm: "120px", md: "250px", lg: "300px" }}
            onClick={() =>
              setActiveBullets({
                import: true,
                details: false,
                requirements: false,
                review:false
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: "120px", md: "250px", lg: "300px" },
                height: "3px",
                bg: activeBullets.details ? textColor : "gray.200",
                left: { sm: "12px", md: "26px" },
                top: { sm: activeBullets.import ? "6px" : "4px", md: null },
                position: "absolute",
                bottom: activeBullets.import ? "40px" : "38px",
                zIndex: -1,
                transition: "all .3s ease",
              }}
            >
              <Icon
                as={BsCircleFill}
                color={activeBullets.import ? textColor : "gray.300"}
                w={activeBullets.import ? "16px" : "12px"}
                h={activeBullets.import ? "16px" : "12px"}
                mb="8px"
              />
              <Text
                color={activeBullets.import ? { textColor } : "gray.300"}
                fontWeight={activeBullets.import ? "bold" : "normal"}
                display={{ sm: "none", md: "block" }}
                fontSize="sm"
              >
                {!importing ? "Start" : importing == "importing" ? "Job Import" : importing == "manually" ? "Start" : "Start"}
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={jobDetailsTab}
            _focus="none"
            w={{ sm: "120px", md: "250px", lg: "300px" }}
            onClick={() =>
              setActiveBullets({
                import: true,
                details: true,
                requirements: false,
                review:false,
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: "120px", md: "250px", lg: "300px" },
                height: "3px",
                bg: activeBullets.requirements ? textColor : "gray.200",
                left: { sm: "12px", md: "30px" },
                top: { sm: activeBullets.details ? "6px" : "4px", md: null },
                position: "absolute",
                bottom: activeBullets.details ? "40px" : "38px",
                zIndex: -1,
                transition: "all .3s ease",
              }}
            >
              <Icon
                as={BsCircleFill}
                color={activeBullets.details ? textColor : "gray.300"}
                w={activeBullets.details ? "16px" : "12px"}
                h={activeBullets.details ? "16px" : "12px"}
                mb="8px"
              />
              <Text
                color={activeBullets.details ? { textColor } : "gray.300"}
                fontWeight={activeBullets.details ? "bold" : "normal"}
                display={{ sm: "none", md: "block" }}
                fontSize="sm"
              >
                Job Details
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={requirementsTab}
            _focus="none"
            w={{ sm: "120px", md: "250px", lg: "300px" }}
            onClick={() =>
              setActiveBullets({
                import: true,
                details: true,
                requirements: true,
                review:false
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: "70px", md: "250px", lg: "300px" },
                height: "3px",
                bg: activeBullets.review ? textColor : "gray.200",
                left: { sm: "12px", md: "35px" },
                top: { sm: activeBullets.requirements ? "6px" : "4px", md: null },
                position: "absolute",
                bottom: activeBullets.requirements ? "40px" : "38px",
                zIndex: -1,
                transition: "all .3s ease",
              }}
            >
              <Icon
                as={BsCircleFill}
                color={activeBullets.requirements ? textColor : "gray.300"}
                w={activeBullets.requirements ? "16px" : "12px"}
                h={activeBullets.requirements ? "16px" : "12px"}
                mb="8px"
              />
              <Text
                color={activeBullets.requirements ? { textColor } : "gray.300"}
                fontWeight={activeBullets.requirements ? "bold" : "normal"}
                transition="all .3s ease"
                fontSize="sm"
                _hover={{ color: textColor }}
                display={{ sm: "none", md: "block" }}
              >
                Requirements
              </Text>
            </Flex>
          </Tab>
          <Tab
            ref={reviewTab}
            _focus="none"
            w={{ sm: "120px", md: "250px", lg: "300px" }}
            onClick={() =>
              setActiveBullets({
                import: true,
                details: true,
                requirements: true,
                review:true
              })
            }
          >
            <Flex
              direction="column"
              justify="center"
              align="center"
              position="relative"
              _before={{
                content: "''",
                width: { sm: "120px", md: "250px", lg: "300px" },
                height: "3px",
                bg: null,
                left: { sm: "12px", md: "28px" },
                top: { sm: activeBullets.review ? "6px" : "4px", md: null },
                position: "absolute",
                bottom: activeBullets.review ? "40px" : "38px",
                zIndex: -1,
                transition: "all .3s ease",
              }}
            >
              <Icon
                as={BsCircleFill}
                color={activeBullets.review ? textColor : "gray.300"}
                w={activeBullets.review ? "16px" : "12px"}
                h={activeBullets.review ? "16px" : "12px"}
                mb="8px"
              />
              <Text
                color={activeBullets.review ? { textColor } : "gray.300"}
                fontWeight={activeBullets.review ? "bold" : "normal"}
                transition="all .3s ease"
                fontSize="sm"
                _hover={{ color: textColor }}
                display={{ sm: "none", md: "block" }}
              >
                Review
              </Text>
            </Flex>
          </Tab>
          
        </TabList>
        <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
        <TabPanel w={{ sm: "330px", md: "700px", lg: "850px" }} mx="auto">
            <Card>
            { !importing ?
            <>
            <Flex flexDir={'row'} justifyContent={'space-evenly'} w={'100%'}>
              <Button onClick={() => {setImporting("manually"); jobDetailsTab.current.click()}} _hover={{ borderWidth:3, borderColor:"blue.300" }} cursor="pointer" alignItems='center' h={20} justifyContent={'center'} flexDirection={'row'} borderRadius={10} p={4} w={"40%"}>
                <Stack direction={'row'} alignItems={'center'}>
                  <Image src={iconblue} alt="Rez Icon" borderRadius={10} w={"70px"} />
                  <Text fontSize="lg" fontWeight="bold">
                    Manually Create Job
                  </Text>
                </Stack>
              </Button>
          
            
              <Button onClick={() => {setImporting("importing");}} _hover={{ borderWidth:3, borderColor:"blue.300" }} cursor="pointer" alignItems='center' h={20} justifyContent={'center'} flexDirection={'row'} borderRadius={10} p={4} w={"40%"}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Image src={iconblue} alt="Rez Icon" borderRadius={10} w={"70px"} />
                    <Text fontSize="lg"  fontWeight="bold">
                      Import a Job
                    </Text>
                  </Stack>
              </Button>
            </Flex>
            </>

            : importing == 'importing' && parsing ? 
            
                  <Image src={rezimation} alt="GIF" borderRadius={10}/>
            : <>
              <CardHeader mb="40px">
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  textAlign="center"
                  w="100%"
                  mx="auto"
                >
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="4px"
                  >
                    Paste Your Job Below
                  </Text>
                  
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Flex
                    direction={{ sm: "column", md: "row" }}
                    w="100%"
                    mt={-5}
                    mb="24px"
                    h={400}
                  >
                    <Editor width="100%" value={newJob.indeedDesc} handleChange={(e) => {setNewJob(prevJob => ({
                    ...prevJob,
                    indeedDesc: e
                  }));
                  ; stripHtml(e);}}/>
                  </Flex>
                  <Flex justify="space-between">
                    <Button
                        variant="no-hover"
                        bg={bgPrevButton}
                        alignSelf="flex-end"
                        mt="24px"
                        w={{ sm: "75px", lg: "100px" }}
                        h="35px"
                        onClick={() => {setImporting(undefined)}}
                      >
                        <Text fontSize="xs" color="gray.700" fontWeight="bold">
                          PREV
                        </Text>
                      </Button>
                    {!parsed ?
                      <Button
                      variant="no-hover"
                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                      alignSelf="flex-end"
                      mt="24px"
                      w={{ sm: "75px", lg: "100px" }}
                      h="35px"
                      onClick={() => {parseJobDesc()}}
                      >
                        <Text fontSize="xs" color="#fff" fontWeight="bold">
                          IMPORT
                        </Text>
                      </Button> :
                      
                    <Button
                      colorScheme={'blue'}
                      alignSelf="flex-end"
                      mt="24px"
                      w={{ sm: "75px", lg: "100px" }}
                      h="35px"
                      onClick={() => {jobDetailsTab.current.click();}}
                    >
                      <Text fontSize="xs" fontWeight="bold">
                        NEXT
                      </Text>
                    </Button>
                  }
                  </Flex>
                </Flex>
              </CardBody>
              </>}
            </Card>
          </TabPanel>
          <TabPanel w={{ sm: "330px", md: "700px", lg: "850px" }} mx="auto">
            <Card>
              <CardHeader mb="40px">
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  textAlign="center"
                  w="80%"
                  mx="auto"
                >
                  
                  
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="4px"
                  >
                    Tell us about the position
                  </Text>
                  <Text color="gray.400" fontWeight="normal" fontSize="sm">
                    
                  </Text>
                  
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Flex
                    direction={{ sm: "column", md: "row" }}
                    w="100%"
                    mb="24px"
                  >
                    
                    
                    <Stack direction="column" spacing="20px" w="100%">
                    <Flex flexDirection={'row'}>
                      <Flex direction="column" width="55px" alignItems={'center'}>
                      
                      <Flex
                        w="100%"
                        h="50px"
                        mb={1}
                        borderRadius="8px"
                        justify="center"
                        transition=".5s all ease"
                        border="1px solid lightgray"
                        alignItems={'center'}
                        bg={newJob.jobType =='Full Time' ? "blue.500" : null}
                        _hover={{ opacity: "0.8" }}
                        onClick={() =>
                          {if (newJob.jobType != 'Full Time') {setNewJob(prevJob => ({
                            ...prevJob,
                            jobType: 'Full Time'
                          }));
                          }}
                        }
                      >
                        <Checkbox
                          onChange={() =>
                            setNewJob(prevJob => ({
                              ...prevJob,
                              jobType: 'Full Time'
                            }))
                            
                        }
                          display="none"
                        />
                        <Icon
                          as={BsClock}
                          w="20px"
                          h="20px"
                        />
                      </Flex>
                    <Text color={textColor} fontWeight="bold" fontSize="xs">
                      Full Time
                    </Text>
                  </Flex>
                  
                  <Flex direction="column" width="55px" alignItems={'center'} ml={3}
                        mr={3}>
                      
                      <Flex
                        w="100%"
                        h="50px"
                        mb={1}
                        borderRadius="8px"
                        justify="center"
                        transition=".5s all ease"
                        border="1px solid lightgray"
                        alignItems={'center'}
                        bg={newJob.jobType =='Part Time' ? "blue.500" : null}
                        _hover={{ opacity: "0.8" }}
                        onClick={() =>
                        {if (newJob.jobType != 'Part Time') {setNewJob(prevJob => ({
                          ...prevJob,
                          jobType: 'Part Time'
                        }));
                        }}
                      }
                      >
                        <Checkbox
                          onChange={() =>
                            setNewJob(prevJob => ({
                              ...prevJob,
                              jobType: 'Part Time'
                            }))
                            
                          }
                          display="none"
                        />
                        <Icon
                          as={BsClockHistory}
                          w="20px"
                          h="20px"
                        />
                      </Flex>
                    <Text color={textColor} fontWeight="bold" fontSize="xs">
                      Part Time
                    </Text>
                  </Flex>
                  <Flex direction="column" width="55px" alignItems={'center'}>
                    
                      <Flex
                        w="100%"
                        h="50px"
                        mb={1}
                        borderRadius="8px"
                        justify="center"
                        transition=".5s all ease"
                        border="1px solid lightgray"
                        alignItems={'center'}
                        bg={newJob.jobType =='Contract' ? "blue.500" : null}
                        _hover={{ opacity: "0.8" }}
                        onClick={() =>
                          {if (newJob.jobType != 'Contract') {setNewJob(prevJob => ({
                            ...prevJob,
                            jobType: 'Contract'
                          }));
                          }}
                        }
                      >
                        <Checkbox
                          onChange={() =>
                            setNewJob(prevJob => ({
                              ...prevJob,
                              jobType: 'Contract'
                            }))
                            
                          }
                          display="none"
                        />
                        <Icon
                          as={BsPencilSquare}
                          w="20px"
                          h="20px"
                        />
                      </Flex>
                    
                    <Text color={textColor} fontWeight="bold" fontSize="xs">
                    Contract
                    </Text>
                  </Flex>
                  </Flex>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontSize="xs"
                          fontWeight="bold"
                        >
                          Job Title
                        </FormLabel>
                        <Input
                          borderRadius="15px"
                          placeholder="eg. Marketing Manager"
                          fontSize="xs"
                          value={newJob.jobTitle}
                          onChange={(d) => {setNewJob(prevJob => ({
                            ...prevJob,
                            jobTitle: d.target.value
                          }));
                          }}
                        />
                      </FormControl>
                      
                      <Flex flexDirection={'row'}>
                          <Flex direction="column" width="105px" alignItems={'center'}>
                            
                            <Flex
                              w="100%"
                              h="50px"
                              m={1}
                              borderRadius="8px"
                              justify="center"
                              transition=".5s all ease"
                              alignItems={'center'}
                              _hover={{ opacity: "0.8" }}
                             
                            >
                              <Checkbox
                                colorScheme='blue'
                                isChecked={newJob.jobStyle == 'Remote' ? true : false}
                                onChange={() =>
                                  {if (newJob.jobStyle != 'Remote') {setNewJob(prevJob => ({
                                    ...prevJob,
                                    jobStyle: 'Remote'
                                  }));
                                  }}
                                }
                              />
                              <Text color={textColor} fontWeight="bold" pl={2} fontSize="sm">
                                Remote
                              </Text>
                              
                            </Flex>
                          
                          
                          </Flex>
                          
                          <Flex direction="column" width="105px" alignItems={'center'}>
                            
                              <Flex
                                w="100%"
                                h="50px"
                                m={1}
                                borderRadius="8px"
                                justify="center"
                                transition=".5s all ease"
                                alignItems={'center'}
                                _hover={{ opacity: "0.8" }}
                                
                              >
                                <Checkbox
                                colorScheme='blue'
                                  isChecked={newJob.jobStyle == 'In Person' ? true : false}
                                  onChange={() =>
                                    {if (newJob.jobStyle != 'In Person') {setNewJob(prevJob => ({
                                      ...prevJob,
                                      jobStyle: 'In Person'
                                    }));
                                    }}
                                  }
                                />
                                <Text color={textColor} fontWeight="bold" pl={2} fontSize="sm">
                                  In Person
                                </Text>
                                
                              </Flex>
                            
                            
                          </Flex>


                          <Flex direction="column" width="105px" alignItems={'center'}>
                            
                              <Flex
                                w="100%"
                                h="50px"
                                m={1}
                                justify="center"
                                alignItems={'center'}
                                
                              >
                                <Checkbox
                                  colorScheme='blue'
                                  isChecked={newJob.jobStyle == 'Hybrid' ? true : false}
                                  onChange={() =>
                                    {if (newJob.jobStyle != 'Hybrid') {setNewJob(prevJob => ({
                                      ...prevJob,
                                      jobStyle: 'Hybrid'
                                    }));
                                    }}
                                  }
                                />
                                <Text color={textColor} fontWeight="bold" pl={2} fontSize="sm">
                                  Hybrid
                                </Text>
                                
                              </Flex>
                            
                            
                          </Flex>
                      </Flex>
                      {newJob.jobStyle == "In Person" || newJob.jobStyle == "Hybrid" ?
                      <FormControl borderRadius={15}>
                      <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                        Job Location
                      </FormLabel>
                      <Input
                          borderRadius="15px"
                          placeholder="GA"
                          fontSize="xs"
                          onChange={(e) => setNewJob(prevJob => ({
                            ...prevJob,
                            jobState: e.target.value.toUpperCase()
                          }))
                          }
                          value={newJob.jobState}
                        />
                    </FormControl>
                    : null}

                    
                    </Stack>
                    
                    
                  
                    
                  </Flex>
                  <Flex justify="space-between">
                    <Button
                      variant="no-hover"
                      bg={bgPrevButton}
                      alignSelf="flex-end"
                      mt="24px"
                      w={{ sm: "75px", lg: "100px" }}
                      h="35px"
                      onClick={() => { 
                        if(importing == "manually"){
                          setImporting(undefined)
                          indeedDescriptionTab.current.click()
                         } else {
                          indeedDescriptionTab.current.click()
                         }
                        
                        }}
                    >
                      <Text fontSize="xs" color="gray.700" fontWeight="bold">
                        PREV
                      </Text>
                    </Button>
                    <Button
                      colorScheme={'blue'}
                      alignSelf="flex-end"
                      mt="24px"
                      w={{ sm: "75px", lg: "100px" }}
                      h="35px"
                      onClick={() => {requirementsTab.current.click(); }}
                    >
                      <Text fontSize="xs" fontWeight="bold">
                        NEXT
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel w={{ sm: "330px", md: "700px", lg: "850px" }} mx="auto">
            <Card>
              <CardHeader mb="40px">
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  textAlign="center"
                  w="80%"
                  mx="auto"
                >
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="4px"
                  >
                    What are the requirements for this position?
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Stack
                    direction={{ sm: "column", md: "row" }}
                    spacing={{ sm: "20px", lg: "35px" }}
                    alignSelf="center"
                    justifySelf="center"
                    mb="24px"
                    w="100%"
                  >
                    <Flex
                      direction="column"
                      align="flex-start"
                      justify="flex-start"
                      textAlign="left"
                      w="90%"
                      mx="auto"
                    >
                    <Flex width="100%" flexDirection={'row'} justifyContent={'space-between'}>
                      <Text
                        color={textColor}
                        fontSize="md"
                        fontWeight="bold"
                        mb="0px"
                      >
                        Education
                      </Text>
                      <Button fontSize='xs' mt={-2} colorScheme={'blue'} onClick={handleAddNewEdu}>Add Education</Button>
                    </Flex>
                   

                    <Flex flexDir={'row'} flexWrap="wrap" align={'flex-start'} w={'100%'} justifyContent='space-between'>
                      
                      {newJob.education.map((edu,index) => 
                      <Flex w={'100%'} mt={3} h={50} key={index} alignSelf='flex-start' shadow='md' borderWidth={1} p={5} borderRadius={100} alignItems="center">
                        <FormControl>
                          <Input
                            fontSize="md"
                            
                            value={edu.educationRequirements}
                            onChange={(e) => handleEduChange(e,index)}
                            placeholder="Bachelors Degree in Computer Science"
                            focusBorderColor="#fff"
                            variant={'unstyled'}
                          />
                        </FormControl>
                        
                        <Button
                          mt={0}
                          mr={-2}
                          ml={1}
                          p={-5}
                          size="sm"
                          borderRadius={100}
                          onClick={() => handleRemoveEdu(index)}
                        >
                          <Icon 
                            as={BsXCircle}
                            w="20px" 
                            h="20px"
                            color={'gray.400'}
                            m={2}
                          />
                        </Button>
                      </Flex>
                    )}
                                
                    </Flex>
                    <Flex mt={8} width="100%" flexDirection={'row'} justifyContent={'space-between'}>
                      <Text
                          color={textColor}
                          fontSize="md"
                          fontWeight="bold"
                        >
                        Skills
                      </Text>
                      <Button mt={-3} fontSize='xs' colorScheme={'blue'} onClick={handleAddNew}>Add Skill</Button>
                    </Flex>
                    <Flex flexDir={'row'} flexWrap="wrap" align={'flex-start'} w={'100%'} justifyContent='space-between'>
                      
                    {newJob.skills.map((skill,index) => 
                      <Flex w={'49%'} mt={3} h={50} key={index} alignSelf='flex-start' shadow='md' borderWidth={1} p={5} borderRadius={100} alignItems="center">
                        <FormControl>
                          <Input
                            fontSize="md"
                            
                            value={skill.skill}
                            onChange={(e) => handleSkillChange(e,index)}
                            placeholder="Skill"
                            focusBorderColor="#fff"
                            variant={'unstyled'}
                          />
                        </FormControl>
                        
                        <Button
                          mt={0}
                          mr={-2}
                          ml={1}
                          p={-5}
                          size="sm"
                          borderRadius={100}
                          onClick={() => handleRemove(index)}
                        >
                          <Icon 
                            as={BsXCircle}
                            w="20px" 
                            h="20px"
                            color={'gray.400'}
                            m={2}
                          />
                        </Button>
                      </Flex>
                    )}
                    </Flex>

                    <Flex mt={8} width="100%" flexDirection={'row'} justifyContent={'space-between'}>
                      <Text
                          color={textColor}
                          fontSize="md"
                          fontWeight="bold"
                        >
                        Responsibilities
                      </Text>
                      <Button mt={-3} fontSize='xs' colorScheme={'blue'} onClick={handleAddNewRes}>Add Responisbility</Button>
                    </Flex>

                    <Flex flexDir={'row'} flexWrap="wrap" align={'flex-start'} w={'100%'} justifyContent='space-between'>
                      
                    {newJob.responsibilities.map((res,index) => 
                      <Flex w={'100%'} mt={3} h={50} key={index} alignSelf='flex-start' shadow='md' borderWidth={1} p={5} borderRadius={100} alignItems="center">
                        <FormControl>
                          <Input
                            fontSize="md"
                            
                            value={res.responsibility}
                            onChange={(e) => handleResChange(e,index)}
                            placeholder="Responsibility"
                            focusBorderColor="#fff"
                            variant={'unstyled'}
                          />
                        </FormControl>
                        
                        <Button
                          mt={0}
                          mr={-2}
                          ml={1}
                          p={-5}
                          size="sm"
                          borderRadius={100}
                          onClick={() => handleRemoveRes(index)}
                        >
                          <Icon 
                            as={BsXCircle}
                            w="20px" 
                            h="20px"
                            color={'gray.400'}
                            m={2}
                          />
                        </Button>
                      </Flex>
                    )}
                    </Flex>



                    


                    </Flex>
                    
                  </Stack>

                  <Flex justify="space-between">
                    <Button
                      variant="no-hover"
                      bg={bgPrevButton}
                      alignSelf="flex-end"
                      mt="24px"
                      w={{ sm: "75px", lg: "100px" }}
                      h="35px"
                      onClick={() => jobDetailsTab.current.click()}
                    >
                      <Text fontSize="xs" color="gray.700" fontWeight="bold">
                        PREV
                      </Text>
                    </Button>
                    
         
      
                    <Button
                      colorScheme={'blue'}
                      alignSelf="flex-end"
                      mt="24px"
                      w={{ sm: "75px", lg: "100px" }}
                      h="35px"
                      onClick={()=> handleSaveJob()}
                    >
                      <Text fontSize="xs" fontWeight="bold">
                        NEXT
                      </Text>
                    </Button>
                    
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>

          <TabPanel w={{ sm: "330px", md: "700px", lg: "850px" }} mx="auto">
            <Card>
              <CardHeader mb="40px">
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  textAlign="center"
                  w="80%"
                  mx="auto"
                >
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="4px"
                  >
                    You're All set!
                  </Text>
                </Flex>
              </CardHeader>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Flex justifyContent={'space-evenly'}> 
                  <Flex>
                    
                  </Flex>
                  
                    <Button _hover={{ borderWidth:3, borderColor:"blue.300" }} cursor="pointer"  alignItems='center'  h={20} justifyContent={'center'} flexDirection={'row'} borderRadius={10} p={4} w={"40%"}>
                      <Link to={savedJob ? `/admin/jobs/0/${savedJob._id}?tab=2` : `/admin/jobs/`}>
                        <Stack direction={'row'} alignItems={'center'}>
                          <Image src={iconblue} alt="Rez Icon" borderRadius={10} w={"70px"} />
                          <Text fontSize="lg" fontWeight="bold">
                            Upload Resumes
                          </Text>
                        </Stack>
                      </Link>
                    </Button>
                  
                    
                    <Button _hover={{ borderWidth:3, borderColor:"blue.300" }} cursor="pointer"  alignItems='center' h={20} justifyContent={'center'} flexDirection={'row'} borderRadius={10} p={4} w={"40%"}>
                      <Link to="/admin/jobs/0/">
                        <Stack direction={'row'} alignItems={'center'}>
                          <Image src={iconblue} alt="Rez Icon" borderRadius={10} w={"70px"} />
                          <Text fontSize="lg"  fontWeight="bold">
                            Take me to my jobs!
                          </Text>
                        </Stack>
                      </Link>
                    </Button>
                  </Flex>
                  

                  <Flex justify="space-between">
                    <Button
                      variant="no-hover"
                      bg={bgPrevButton}
                      alignSelf="flex-end"
                      mt="24px"
                      w={{ sm: "75px", lg: "100px" }}
                      h="35px"
                      onClick={() => jobDetailsTab.current.click()}
                    >
                      <Text fontSize="xs" color="gray.700" fontWeight="bold">
                        PREV
                      </Text>
                    </Button>
                    
         
      
                    
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
          
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default CreateJob;
