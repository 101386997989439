import React, { useRef, useState,useContext,useEffect } from "react";

import {
  Box,
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Badge,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Icon,
} from "@chakra-ui/react";
import { SocketContext } from '../../../components/Layout/MainPanel'
import { useLocation } from "react-router-dom";

import { BsClock,BsClockHistory,BsPencilSquare } from "react-icons/bs";

function JobDetails({ selectedJob, handleSelectedJobEdit }) {
  const socket = useContext(SocketContext);

  const [isEditing, setIsEditing] = useState(false);
  const [editedJob, setEditedJob] = useState(selectedJob);
  const toggleEdit = () => setIsEditing(!isEditing);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedJob((prevJob) => ({ ...prevJob, [name]: value }));
  };
  const handleSkillsChange = (event) => {
    const skills = event.target.value.split(",").map((skill) => ({
      skill: skill.trim(),
    }));
    setEditedJob((prevJob) => ({ ...prevJob, skills }));
  };
  const handleAddRequirement = () => {
    setEditedJob((prevJob) => ({
      ...prevJob,
      education: [
        ...prevJob.education,
        { educationRequirements: "" }
      ],
    }));
  };
  const handleRemoveRequirement = (index) => {
    setEditedJob((prevJob) => ({
      ...prevJob,
      education: prevJob.education.filter((_, i) => i !== index),
    }));
  };
  const handleAddResponsibility = () => {
    setEditedJob((prevJob) => ({
      ...prevJob,
      responsibilities: [
        ...prevJob.responsibilities,
        { responsibility: "" }
      ],
    }));
  };
  const handleRemoveResponsibility = (index) => {
    setEditedJob((prevJob) => ({
      ...prevJob,
      responsibilities: prevJob.responsibilities.filter((_, i) => i !== index),
    }));
  };
  const handleSave = () => {
    // Save edited job
    console.log(editedJob);
    socket.emit('editJob', editedJob)
    setIsEditing(false);
    handleSelectedJobEdit(editedJob)
  };
  const handleCancel = () => {
    // Reset edited job to original job
    setEditedJob(selectedJob);
    setIsEditing(false);
  };

  return (
    <Flex>
      <Box w="100%" p="2">
        <Flex width="100%" flexDirection={'row'} justifyContent={'space-between'} mb="2" borderBottomWidth={3} pb={3}>
            <Text fontSize="xl" fontWeight="bold" mt="2">
              Job Details
            </Text>
            <Button onClick={toggleEdit} colorScheme="blue">
            <Icon
                          as={BsPencilSquare}
                          w="20px"
                          h="20px"
                          mr={3}
                        />
              Edit
            </Button>
              
        </Flex>
        
        {!isEditing ? (
          <>
            <Text fontSize="md" fontWeight="bold" mb="1" >
              Job Title:
            </Text>
            <Text fontSize="md" mb="2">
              {selectedJob.jobTitle}
            </Text>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Job Type:
            </Text>
            <Text fontSize="md" mb="2">
              {selectedJob.jobType}
            </Text>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Job Style:
            </Text>
            <Text fontSize="md" mb="2">
              {selectedJob.jobStyle}
            </Text>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Job State:
            </Text>
            <Text fontSize="md" mb="2">
              {selectedJob.jobState}
            </Text>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Description:
            </Text>
            <Text fontSize="md" mb="2">
              {selectedJob.description}
            </Text>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Responsibilities:
            </Text>
            <UnorderedList mb="2">
              {selectedJob.responsibilities.map((item, index) => (
                <ListItem key={index} fontSize="md" ml="4">
                  {item.responsibility}
                </ListItem>
              ))}
            </UnorderedList>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Skills:
            </Text>
            <Flex flexWrap="wrap" mb="2">
              {selectedJob.skills.map((item, index) => (
                <Badge key={index} mr="2" mb="2">
                  {item.skill}
                </Badge>
              ))}
            </Flex>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Education Requirements:
            </Text>
            <UnorderedList mb="2">
            {selectedJob.education.map((item, index) => (
             <ListItem key={index} fontSize="md" ml="4">
                  {item.educationRequirements}
             </ListItem>
            ))}
            </UnorderedList>
          </>
        ) : (
          <>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Job Title:
            </Text>
            <Input
              type="text"
              name="jobTitle"
              value={editedJob.jobTitle}
              onChange={handleChange}
              mb="2"
            />
            <Text fontSize="md" fontWeight="bold" mb="1">
              Job Type:
            </Text>
            <Input
              type="text"
              name="jobType"
              value={editedJob.jobType}
              onChange={handleChange}
              mb="2"
            />
            <Text fontSize="md" fontWeight="bold" mb="1">
              Job Style:
            </Text>
            <Input
              type="text"
              name="jobStyle"
              value={editedJob.jobStyle}
              onChange={handleChange}
              mb="2"
            />
            <Text fontSize="md" fontWeight="bold" mb="1">
              Job State:
            </Text>
            <Input
              type="text"
              name="jobState"
              value={editedJob.jobState}
              onChange={handleChange}
              mb="2"
            />
            <Text fontSize="md" fontWeight="bold" mb="1">
              Description:
            </Text>
            <Input
              type="text"
              name="description"
              value={editedJob.description}
              onChange={handleChange}
              mb="2"
            />
            <Flex width="100%" flexDirection={'row'} justifyContent={'space-between'}>
                      <Text fontSize="md" fontWeight="bold" mb="1">
                        Responsibilities
                      </Text>
                      <Button onClick={handleAddResponsibility} colorScheme="blue" mb="2">
                        Add Responsibility
                      </Button>
            </Flex>
            <UnorderedList mb="2">
              {editedJob.responsibilities.map((item, index) => (
                <ListItem key={index} fontSize="md" ml="4">
                  <Flex>
                    <Input
                      type="text"
                      name={`responsibility-${index}`}
                      value={item.responsibility}
                      onChange={(event) => {
                        const { value } = event.target;
                        setEditedJob((prevJob) => {
                          const responsibilities = prevJob.responsibilities.map(
                            (responsibility, i) =>
                              i === index
                                ? { responsibility: value }
                                : responsibility
                          );
                          return { ...prevJob, responsibilities };
                        });
                      }}
                      mr="2"
                    />
                    <Button
                      onClick={() => handleRemoveResponsibility(index)}
                      colorScheme="red"
                      size="sm"
                    >
                      Remove
                    </Button>
                  </Flex>
                </ListItem>
              ))}
              
              
            </UnorderedList>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Skills:
            </Text>
            <Input
              type="text"
              name="skills"
              value={editedJob.skills.map((item) => item.skill).join(", ")}
              onChange={handleSkillsChange}
              mb="2"
            />
            <Flex width="100%" flexDirection={'row'} justifyContent={'space-between'}>
                      <Text fontSize="md" fontWeight="bold" mb="1">
                        Education Requirements
                      </Text>
                      <Button onClick={handleAddRequirement} colorScheme="blue" mb="2">
                        Add Requirement
                      </Button>
            </Flex>
            {editedJob.education.map((item, index) => (
              <Flex key={index} mb="2">
                <Input
                  type="text"
                  name={`requirement-${index}`}
                  value={item.educationRequirements}
                  onChange={(event) => {
                    const { value } = event.target;
                    setEditedJob((prevJob) => {
                      const education = prevJob.education.map(
                        (requirement, i) =>
                          i === index
                            ? { educationRequirements: value }
                            : requirement
                      );
                      return { ...prevJob, education };
                    });
                  }}
                  mr="2"
                />
                <Button
                  onClick={() => handleRemoveRequirement(index)}
                  colorScheme="red"
                  size="sm"
                >
                  Remove
                </Button>
              </Flex>
            ))}
            
            <Flex justifyContent="flex-end">
              <Button mr="2" onClick={handleSave} colorScheme="blue">
                Save
              </Button>
              <Button onClick={handleCancel} colorScheme="gray">
                Cancel
              </Button>
            </Flex>
          </>
        )}
      </Box>
    </Flex>
  );
}

export default JobDetails;

                     

