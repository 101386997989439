import React from "react";
import './typingIndicator.css';
import { BsHeartFill } from "react-icons/bs";
import { Icon } from '@chakra-ui/react'



const TypingIndicator = () => {
  return (
    
       
        <div className="bouncing-dot" backgroundColor="#000" justifyContent="flex-end">
          <div>
            <Icon 
              as={BsHeartFill} 
              color={"#40BE04"}
              _hover={{ color: '#40BE04' }} 
              w="15px" 
              h="15px" 
              ml={1}
              
              />
          </div>
          <div>
            <Icon 
                as={BsHeartFill} 
                color={"#40BE04"}
                _hover={{ color: '#40BE04' }} 
                w="15px" 
                h="15px" 
                ml={1}
                
                />
          </div>
          <div>
            <Icon 
                as={BsHeartFill} 
                color={"#40BE04"}
                _hover={{ color: '#40BE04' }} 
                w="15px" 
                h="15px" 
                ml={1}
                
                />
          </div>
        </div>
      
    
  );
};

export default TypingIndicator;
