import { Box, useStyleConfig } from "@chakra-ui/react";
import { useEffect, useState, createContext } from 'react';
import io from 'socket.io-client';
import { Auth } from 'aws-amplify';
import { Environment } from '../../variables/environment';
import { Redirect } from "react-router-dom";
async function getUserEmail() {
  try {
      const user = await Auth.currentAuthenticatedUser();
      const e = user.attributes.email;
      const n = user.attributes.name;
      setEmail(e)
      setName(n)
      return true;
  } catch (error) {
      console.error(error);
  }
}

const SocketContext = createContext();
var socketUrl;
if (Environment == 'local') {
  socketUrl = 'http://localhost:3001'
} else {
  socketUrl = 'https://socket.rezgenie.com'
}


function MainPanel(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("MainPanel", { variant });
  const [socket,setSocket] = useState(undefined);
  const [status,setStatus] = useState('loading');

  useEffect(() => {

    conn();
    
}, []);

  const conn = async () => {
    try {
        try{
        const u = await Auth.currentAuthenticatedUser();
        } catch (e){
          setStatus(false)
        }
        
        const session = await Auth.currentSession();
        
        const token = session.getIdToken().getJwtToken();
        
        var user = await Auth.currentAuthenticatedUser();
        var email = user.attributes.email;
        var companyId = user.attributes['custom:companyId'];
        var userId = user.attributes['custom:userId'];
        var role = user.attributes['custom:role'];
        setSocket(io(socketUrl, {
            query: {
                token: token,
                email: email,
                companyId: companyId,
                userId: userId,
                role: role
            }
                }))
        
      } catch (error) {
        console.error(error);
      }
}

    
    return (
      
      <SocketContext.Provider value={socket}>
        {status == false ? <Redirect to="/" /> :
        <Box __css={styles} {...rest}>
          
            {children}
          
        </Box>
        }
      </SocketContext.Provider>
    
    );
    

  
  
}
export { SocketContext};
export default MainPanel;
