import React, { useEffect, useRef } from "react";
import { Avatar, Flex, Icon, Textarea,Tooltip,Text,Button, Box, Image, Modal, ModalOverlay, ModalContent, ModalCloseButton,ListItem } from "@chakra-ui/react";
import { format, compareAsc } from 'date-fns';
import TypingIndicator from "../components/TypingIndicator";
import { useState } from 'react';
import { BsFillCloudArrowDownFill, BsSuitHeartFill, BsArrowReturnLeft,BsPencilSquare,BsArrowDownCircleFill } from "react-icons/bs";
import { Fade, ScaleFade, Slide, SlideFade } from '@chakra-ui/react';
import ResizeTextarea from "react-textarea-autosize";


import UrlMetadata from 'url-metadata';

const formatFileName = (fileName) => {
	fileName = decodeURIComponent(fileName);
	let firstSplit = fileName.split('/').pop()
	var final = firstSplit.split('.$plit.').pop()
	if (final.length < 15){
		var n = final
	} else {
		var n = final.substring(0, 15) + "..." + final.split('.').pop()
	}
	return n.toString()
}




const displayUrlMetaData = async (url) => {
    try {
        const { title, description, image, icon, site_name } = await UrlMetadata(url)
        
        return (
            <Flex p={2} bg="white" rounded="md" flexDirection="column">
                <Text fontSize="sm" fontWeight="bold">{title}</Text>
                <Text fontSize="sm" color="gray.600">{description}</Text>
                <Flex my={2}>
                    <Avatar src={icon} size="sm" mr={2} />
                    <Flex flexDirection="column">
                        <Text fontSize="xs" fontWeight="medium">{site_name}</Text>
                        <Text fontSize="xs" color="gray.500">{url}</Text>
                    </Flex>
                </Flex>
                {image && <Image src={image} w="100%" h="100px" objectFit="cover" />}
            </Flex>
        )
    } catch (error) {
        console.error(error)
    }
}

			
 


const Messages = ({ messages, email, userTyping, handleLoadMoreMessages,selectedChat,numMessages,loadingMessages,handleSetReply,edit,setEdit,handleKeyDown,handleSendEdit }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [metadata, setMetadata] = useState({});
	const containerRef = useRef(null);
	const elementRef = useRef();
	const [atBottom, setAtBottom] = useState(true);
	const lastElementRef = useRef()
	const [hoveredMessageIndex, setHoveredMessageIndex] = useState(-1);
	const ref = useRef();
	const [editMessage, setEditMessage] = useState(null);


	
	

	useEffect(() => {
		console.log(messages.length)
		if (atBottom) {
			// Scroll to the bottom if user is already at the bottom
			elementRef.current.scrollIntoView();
		}
	}, [messages]);

	
	useEffect(() => {
		if (lastElementRef.current && loadingMessages) {
			console.log(messages.length)
			
			lastElementRef.current.scrollIntoView();
		}
	}, [messages]);

	useEffect(() => {
		const handleScroll = async() => {
			var totalHeight = containerRef.current.clientHeight
			var bottom = containerRef.current.scrollHeight - containerRef.current.scrollTop;
			if (bottom == totalHeight) {
				setAtBottom(true)
			} else if (bottom != totalHeight && atBottom) {
				setAtBottom(false)
			}
			  
		  if (containerRef.current.scrollTop === 0) {
			
			await handleLoadMoreMessages()
		  }
		}
	
		containerRef.current.addEventListener('scroll', handleScroll);
		return () => {
		  containerRef.current.removeEventListener('scroll', handleScroll);
		}
	  }, [messages]);

	const ReplyFormat = ({r}) => {
			return (
				<Flex flexDirection={'column'} m={1} minW="89%" backgroundColor={'#D0EACD'} borderRadius={7}>
				<Flex flexDirection={'row'} justifyContent='space-between' ml={2} mt={1}>
					<Text as='b' fontSize={14} color={'#01BE04'}>{r ? r.senderName : null}</Text>
				</Flex>
				<Flex flexDirection={'row'} ml={2} pb={2}>
					<Flex w={'2.3px'} backgroundColor='#01BE04' borderRadius={20} mr={2}></Flex>
					<Box flexDirection={'column'} alignItems={'center'} overflow='hidden' maxW={400}>
					{/^(http|https):\/\/[^ "]+$/.test(r.message) && r.type == 'text' ?
								<a href={r.message} target="_blank">{r.message}</a>
								:
								r.type !== 'png' && r.type !== 'jpg' && r.type !== 'jpeg' && r.type !== 'text' ? (
								<Box as="a" href={r.message} target="_blank" download display="flex" alignItems="center" justifyContent="center" p={4} borderWidth={1} rounded="md">
									<Box as={BsFillCloudArrowDownFill} size={20} />
									<Text ml={2}>{formatFileName(r.message)}</Text>
								</Box>
								) : (
								r.type === 'png' || r.type === 'jpg' || r.type === 'jpeg' ? (
									<Image
									src={r.message}
									alt="image message"
									w={'30%'}
									h={'auto'}
									borderRadius={10}
									
									/>
								) : (
									<Text fontSize={14} style={{ whiteSpace: 'pre-wrap' }}>{r.message}</Text>
								)
								)}
					</Box>
				</Flex>
			</Flex>
			)

		
	}


	return (
		<>
		
		<Flex w="100%" h="60vh" mb={3} overflowY="scroll" flexDirection="column" p="3" ref={containerRef} sx={{
			'&::-webkit-scrollbar': {
			width: '5px',
			},
			'&::-webkit-scrollbar-track': {
			width: '6px',
			background:'#f1f1f1'
			},
			'&::-webkit-scrollbar-thumb': {
			background: '#01BE04',
			borderRadius: '24px',
			},
		}}>
		
		{messages.map((item, index) => {
			const isLink = /^(http|https):\/\/[^ "]+$/.test(item.message);
			
				return (
					<ScaleFade initialScale={1} in={true}>
						<Flex
							onMouseEnter={() => setHoveredMessageIndex(index)}
							onMouseLeave={() => setHoveredMessageIndex(-1)}
							key={index}
							w="100%"
							ref={numMessages === index ? lastElementRef : null}
							p={2}
							m={2}
							borderRadius={10}
							backgroundColor={hoveredMessageIndex === index ? "#F1F1F1" : "none"}
							flexDirection="column"
						>
							<Box mb={-9} justifyContent={"flex-end"} alignItems="center" minH={9}>
							{hoveredMessageIndex === index ? (
								<ScaleFade initialScale={0.9} in={hoveredMessageIndex === index}>
								<Flex justifyContent="flex-end" alignItems="center">
									<Flex
									borderWidth={2}
									borderRadius={10}
									backgroundColor="#FFF"
									justifyContent="center"
									alignItems="center"
									>
									<Button
										alignItems="center"
										backgroundColor="#FFF"
										justifyContent="center"
										_hover={{ color: "#40BE04" }}
										color={"#888888"}
										borderLeftRadius={10}
										size={"sm"}
										onClick={() => {
										console.log(item._id);
										}}
									>
										<Icon as={BsSuitHeartFill} w="20px" h="20px" />
									</Button>
									<Tooltip hasArrow label='Edit' placement='top'>
									<Button
										display={item.sender == email ? "flex" : "none"}
										alignItems="center"
										justifyContent="center"
										backgroundColor="#FFF"
										_hover={{ color: "#40BE04" }}
										color={"#888888"}
										borderLeftRadius={10}
										size={"sm"}
										onClick={() => {
										setEditMessage(item.message)
										setEdit(item)
										}}
									>
										
										<Icon as={BsPencilSquare} w="20px" h="20px" />
									</Button>
									</Tooltip>
									<Tooltip hasArrow label='Reply' placement='top'>
									<Button
										alignItems="center"
										justifyContent="center"
										backgroundColor="#FFF"
										_hover={{ color: "#40BE04" }}
										color={"#888888"}
										borderLeftRadius={10}
										size={"sm"}
										onClick={() => {
										handleSetReply(item);
										}}
									>
										
										<Icon as={BsArrowReturnLeft} w="20px" h="20px" />
									</Button>
									</Tooltip>
									</Flex>
								</Flex>
								</ScaleFade>
							) : null}
							</Box>
							<Flex
							justify={item.sender == email ? "flex-end" : "flex-start"}
							alignItems={"flex-start"}
							
							>
							<Avatar mt={3.5} src={item.senderAvatar} />
							<Flex color="black" minW="100px" maxW="350px" my="1" p="3">
								<Box flexDirection={"column"} alignItems={"center"} overflow="hidden" >
								<Flex flexDirection={"row"} alignItems={"center"}>
									<Text fontSize={14} as="b">
									{item.senderName}
									</Text>
									<Text ml={2} fontSize={12} color="#67758B">
									{" "}
									{format(new Date(item.timestamp), "MMM dd, yyyy, p")}
									</Text>
								</Flex>
								{item.reply ?
								<ReplyFormat r={item.reply}/>
								: null }
								{
								isLink && item.type == "text" ? (
									
									<Text>
									<a href={item.message} target="_blank">
										{item.message}
									</a>
									</Text>
								) : item.type !== "png" &&
									item.type !== "jpg" &&
									item.type !== "jpeg" &&
									item.type !== "text" ? (
									<Box
									as="a"
									href={item.message}
									target="_blank"
									download
									display="flex"
									alignItems="center"
									justifyContent="center"
									p={4}
									borderWidth={1}
									rounded="md"
									>
									<Box as={BsFillCloudArrowDownFill} size={20} />
									<Text ml={2}>{formatFileName(item.message)}</Text>
									</Box>
								) : item.type === "png" ||
									item.type === "jpg" ||
									item.type === "jpeg" ? (
									<Image
									src={item.message}
									alt="image message"
									m={2}
									borderRadius={10}
									w={200}
									h={"auto"}
									onClick={() => {
										setIsOpen(true);
										setSelectedImage(item.message);
									}}
									/>
								) : edit && edit._id === item._id ?
									<Textarea
										minH="unset"
										overflow="hidden"
										w="100%"
										resize="none"
										mt={1}
										ref={ref}
										minRows={1}
										as={ResizeTextarea}
										placeholder="Type Something..."
										border="none"
										borderRadius="none"
										_focus={{
										border: "0px solid black",
										}}
										onKeyDown={handleKeyDown}
										onKeyPress={(e) => {
											if (e.key === "Enter" && !e.shiftKey) {
												e.preventDefault();
												handleSendEdit(editMessage);
												setEditMessage(false)
											}
											if (e.key === "Enter" && e.shiftKey) {
												e.preventDefault();
												setEdit((m) => { m.message = m.message + "\n"; return m });
											}
										}}
										
										value={editMessage}
										onChange={(e) => setEditMessage(e.target.value)}
									/>
								:
									<Text style={{ whiteSpace: 'pre-wrap' }} >{item.message}</Text>
									
								}
								<Flex
									flexDirection={"row"}
									alignItems={"center"}
									justifyContent={"flex-end"}
								>
									{item.sender == email &&
									item.readBy.length > 0 &&
									index === messages.length - 1 &&
									item.readBy.map((readReceipt, index) => {
										return readReceipt.email !== item.sender ? (
										<Flex key={index} mt={2} ml={0.1} mr={1}>
											<Avatar h={5} w={5} src={readReceipt.avatarUrl} />
										</Flex>
										) : null;
									})}
								</Flex>
								</Box>
							</Flex>
							</Flex>
						</Flex>
						</ScaleFade>
					
			);
		
	})}
	
	{!atBottom 
	? 
	
		<Icon  position={'absolute'}  justifySelf={'flex-end'} as={BsArrowDownCircleFill} w="37px" h="27px" alignSelf={'flex-end'} onClick={() => elementRef.current.scrollIntoView()}/>
	
	
	: 
	null}

	<div ref={elementRef} />
	{isOpen && (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} >
          <ModalOverlay />
          <ModalContent maxH={600} maxW={800} justifyContent="center" alignItems="center">
            <ModalCloseButton />
            <Image src={selectedImage} alt="selected image" maxW={'80%'} maxH={'600'}/>
          </ModalContent>
        </Modal>
      )}
	  {userTyping && userTyping.status ? 
	<Flex position={'static'} flexDirection={'row'} justifyContent={'flex-start'} pr={10} height={'1'}>
		<TypingIndicator isTyping={userTyping} />
	</Flex>

	: null}
	
	
	  
	</Flex>
	
	
	
	</>
	);
};

export default Messages;


