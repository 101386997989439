import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useColorModeValue
} from "@chakra-ui/react";
import { SocketContext } from "../../../components/Layout/MainPanel";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ListResumes({ selectedJob }) {
  const socket = useContext(SocketContext);
  const [resumes, setResumes] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  let bgColor = useColorModeValue("#EDF2F7", "#313843");

  useEffect(() => {
    socket.emit("getResumes", selectedJob._id);
    socket.on("resumes", (resumes) => {
      setResumes(resumes);
      setSelectedResume(resumes[0])
    });

    return () => {
      socket.off("resumes");
    };
  }, [selectedJob, socket]);

  const handleResumeClick = (resume) => {
    if (selectedResume === resume) {
      setShowModal(true);
    } else {
      setSelectedResume(resume);
      setShowModal(false);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const formatFileName = (fileName) => {
    fileName = decodeURIComponent(fileName);
    let firstSplit = fileName.split("/").pop();
    var final = firstSplit.split(".$split.").pop();
    if (final.length < 40) {
      var n = final;
    } else {
      var n = final.substring(0, 40) + "..." + final.split(".").pop();
    }
    return n.toString();
  };

  return (
    <Flex>
      <Box w="35%" p="2">
        <Text fontSize="lg" fontWeight="bold" mb="2">
          Resumes
        </Text>
        {resumes.map((resume) => (
          <Box
            key={resume._id}
            onClick={() => handleResumeClick(resume)}
            cursor="pointer"
            p="2"
            _hover={{ borderColor: "blue.300",borderWidth:"3px"}} borderColor={bgColor} borderWidth="3px"
            style={{borderRadius:10,backgroundColor:bgColor}}
            borderRadius="md"
            mb="4"
          >
            <Text fontWeight={"medium"}>{formatFileName(resume.key)}</Text>
          </Box>
        ))}
      </Box>
      <Box w="100%" bg={'#000'} borderRadius={10}>
        {selectedResume && (
          <Flex onClick={() => handleResumeClick(selectedResume)} m={5} justifyContent={'center'} >
            <Document
              file={selectedResume.Location}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} renderTextLayer={false} width={650}/>
            </Document>
          </Flex>
        )}
        {showModal && (
          <Modal isOpen={showModal} onClose={() => setShowModal(false)} >
            <ModalOverlay/>
            <ModalContent maxW={"60%"} bg={'#000'} >
            <ModalHeader>
                <Flex w={"100%"} justifyContent={'space-between'} alignItems={"center"} flexDirection={'row'} mt={5}>
                    <Text ml={5} color={'#FFF'}>
                        Page {pageNumber} of {numPages}
                    </Text>
                    <Flex w={"30%"} justifyContent={'space-evenly'} alignItems={"center"} flexDirection={'row'}>
                        <Button onClick={previousPage}>
                            Previous
                        </Button>
                        <Button onClick={nextPage}>Next</Button>
                    </Flex>
                </Flex>
            </ModalHeader>
              
              <ModalBody >
                <Flex onClick={() => handleResumeClick(selectedResume)} m={5} justifyContent={'center'} >
                    <Document
                    file={selectedResume.Location}
                    onLoadSuccess={onDocumentLoadSuccess}
                    >
                    <Page pageNumber={pageNumber} renderTextLayer={false} width={800} height={1000}/>
                    </Document>
                </Flex>
                
              </ModalBody>
              
            </ModalContent>
          </Modal>
        )}
      </Box>
    </Flex>
  );
}

export default ListResumes;

