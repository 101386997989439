import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useColorModeValue,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { Auth } from 'aws-amplify';
import { SocketContext } from "../../../components/Layout/MainPanel";


function CheckoutForm() {
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  const socket = useContext(SocketContext);
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingZip, setBillingZip] = useState("");
  const [plan, setPlan] = useState(undefined);
  const cardColor = useColorModeValue("#000", "white");
  
  const plans = {
    plan1: 100,
    plan2: 200,
    plan3: 300
  }

  useEffect( () => {
    if (socket) {
    
      socket.on("payment_result", (data) => {
        setLoading(false);
        if (data.succeeded) {
          toast({
            title: "Payment Successful!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Payment Failed!",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
    }

  }, [socket])

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if(!plan) {
      toast({
        title: "Select a plan first",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    
    setLoading(true);
    
    var user = await Auth.currentAuthenticatedUser();
    var email = user.attributes.email;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: name,
        email: email,
        address: {
          line1: billingAddress,
          line2: billingAddress2,
          city: billingCity,
          state: billingState,
          postal_code: billingZip,
        },
      },
    });

    

    if (error) {
      setError(error.message);
      console.log(error.message)
    } else {
      // Payment successful, submit form or handle success in your app
      console.log(paymentMethod)
      console.log(paymentMethod.id)

      // Emit make_payment event to server
      socket.emit('make_payment', {
        amount: plan === "1" ? plans.plan1 : plan === "2" ? plans.plan2 : plans.plan3,
        currency: "usd",
        payment_method: paymentMethod.id,
        customer_email: email,
        plan: plan
      });

      
    }
  };

  const handlePlanChange = (event) => {
    setPlan(event);
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Heading as="h2" mb="4">
        Add Funds
      </Heading>
      <RadioGroup onChange={handlePlanChange} value={plan}>
        <Stack direction="column" spacing="4">
          <Radio value="1">
            <Flex justify="space-between" align="center">
              <Text fontWeight="semibold" mr={3}>Tier I</Text>
              <Text mr={3}>${(plans.plan1 / 100).toFixed(2)}</Text>
              <Text>150 Resumes ($0.65 / resume)</Text>
            </Flex>
          </Radio>
          <Radio value="2">
            <Flex justify="space-between" align="center">
              <Text fontWeight="semibold" mr={3}>Tier II</Text>
              <Text mr={3}>${(plans.plan2 / 100).toFixed(2)}</Text>
              <Text>1,100 Resumes ($0.44 / resume)</Text>
            </Flex>
          </Radio>
          <Radio value="3">
            <Flex justify="space-between" align="center">
              <Text fontWeight="semibold" mr={3}>Tier III</Text>
              <Text mr={3}>${(plans.plan3 / 100).toFixed(2)}</Text>
              <Text>2,700 Resumes ($0.36 / resume)</Text>
            </Flex>
          </Radio>
        </Stack>
      </RadioGroup>
      <FormControl mt="4">
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          placeholder="John Doe"
          value={name}
          onChange={(event) => setName(event.target.value)}
          isRequired
        />
      </FormControl>
      <FormControl mt="4">
        <FormLabel>Billing Address 1</FormLabel>
        <Input
          type="text"
          placeholder="Apt. 123"
          value={billingAddress}
          onChange={(event) => setBillingAddress(event.target.value)}
        />
      </FormControl>
        
      <FormControl mt="4">
        <FormLabel>Billing Address 2</FormLabel>
        <Input
          type="text"
          placeholder="Apt. 123"
          value={billingAddress2}
          onChange={(event) => setBillingAddress2(event.target.value)}
        />
      </FormControl>
      <Flex justify="space-between">
        <FormControl flex="1" mr="2" mt="4">
          <FormLabel>City</FormLabel>
          <Input
            type="text"
            placeholder="San Francisco"
            value={billingCity}
            onChange={(event) => setBillingCity(event.target.value)}
          />
        </FormControl>
        <FormControl flex="1" ml="2" mt="4">
          <FormLabel>State</FormLabel>
          <Input
            type="text"
            placeholder="CA"
            value={billingState}
            onChange={(event) => setBillingState(event.target.value)}
          />
        </FormControl>
      </Flex>
      <FormControl mt="4">
        <FormLabel>ZIP Code</FormLabel>
        <Input
          type="text"
          placeholder="12345"
          value={billingZip}
          onChange={(event) => setBillingZip(event.target.value)}
        />
      </FormControl>
      <Box my="4" borderWidth={1} padding={15} borderRadius={10}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "20px",
                color: cardColor
              }
            },
          }}
        />
      </Box>
      {error && (
      <Box p="2" bg="red.100" color="red.800" borderRadius="md" mb="4">
        {error}
      </Box>
    )}
    <Button type="submit" isLoading={loading} colorScheme="blue" disabled={loading || !plan} >
    
      {plan === "1"
        ? `Pay $${(plans.plan1 / 100).toFixed(2)}`
        : plan === "2"
        ? `Pay $${(plans.plan2 / 100).toFixed(2)}`
        : plan === "3" 
        ? `Pay $${(plans.plan3 / 100).toFixed(2)}`
        : "Select A Tier"
      }
    </Button>
  </Box>
);
}
  
export default CheckoutForm;