

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  HStack,
  Icon,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import bgCard from "assets/img/background-card-reports.png";
import IconBox from "components/Icons/IconBox";
import { CreativeTimLogo, RocketIcon } from "components/Icons/Icons";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React from "react";
import { AiFillStar } from "react-icons/ai";
import { GoChevronDown } from "react-icons/go";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import logoImg from "assets/img/RezGenie_FullColorCroppedWhite.png"

export default function AuthNavbar(props) {
  const [open, setOpen] = React.useState(false);
  const {
    isOpen: isOpenPages,
    onOpen: onOpenPages,
    onClose: onClosePages,
  } = useDisclosure();

  const {
    isOpen: isOpenAuth,
    onOpen: onOpenAuth,
    onClose: onCloseAuth,
  } = useDisclosure();

  const {
    isOpen: isOpenApplication,
    onOpen: onOpenApplication,
    onClose: onCloseApplication,
  } = useDisclosure();

  const {
    isOpen: isOpenEcommerce,
    onOpen: onOpenEcommerce,
    onClose: onCloseEcommerce,
  } = useDisclosure();
  const textColor = useColorModeValue("gray.700", "#fff");
  const { logo, logoText, secondary, ...rest } = props;

  let authObject = {};
  routes.forEach((route) => {
    if (route.items) {
      authObject = route.items.find((link) => link.name === "Authentication");
    }
  });

  let applicationsObject = {};
  routes.forEach((route) => {
    if (route.items) {
      applicationsObject = route.items.find(
        (link) => link.name === "Applications"
      );
    }
  });

  

  // verifies if routeName is the one active (in browser input)

  // Chakra color mode
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarBg = useColorModeValue(
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  let navbarBorder = useColorModeValue(
    "1.5px solid #FFFFFF",
    "1.5px solid rgba(255, 255, 255, 0.31)"
  );
  let navbarShadow = useColorModeValue(
    "0px 7px 23px rgba(0, 0, 0, 0.05)",
    "none"
  );
  let navbarFilter = useColorModeValue(
    "none",
    "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
  );
  let navbarBackdrop = "blur(21px)";
  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  let navbarPosition = "fixed";
  let colorButton = "white";
  if (props.secondary === true) {
    navbarBg = "none";
    navbarBorder = "none";
    navbarShadow = "initial";
    navbarFilter = "initial";
    navbarBackdrop = "none";
    bgButton = "#08C606";
    colorButton = "gray.700";
    mainText = "#fff";
    navbarPosition = "absolute";
  }



  var brand = (
    <Link
      href={`${process.env.PUBLIC_URL}/#/`}
      target="_blank"
      display="flex"
      lineHeight="100%"
      fontWeight="bold"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <img src={logoImg} alt="Logo" style={{height:100,padding:15}} />
      
    </Link>
  );

  const linksAuth = (
    <HStack display={{ sm: "none", lg: "flex" }} spacing="14px">
      <Stack
        direction="row"
        spacing="4px"
        align="center"
        color="#fff"
        fontWeight="bold"
        cursor="pointer"
        position="relative"
      >
        <Text fontSize="sm" color={mainText}>
          
        </Text>
      </Stack>
      <Stack
        direction="row"
        spacing="4px"
        align="center"
        color="#fff"
        fontWeight="bold"
        cursor="pointer"
        position="relative"
      >
        <Text fontSize="sm" color={mainText}>
          
        </Text>
      </Stack>
      <Stack
        direction="row"
        spacing="4px"
        align="center"
        color="#fff"
        fontWeight="bold"
        cursor="pointer"
        position="relative"
      >
        <Text fontSize="sm" color={mainText}>
          
        </Text>
      </Stack>
      <Stack
        direction="row"
        spacing="4px"
        align="center"
        color="#fff"
        fontWeight="bold"
        cursor="pointer"
        position="relative"
      >
        <Text fontSize="sm" color={mainText}>
          
        </Text>
      </Stack>
    </HStack>
  );
  return (
    <Flex
      position={navbarPosition}
      top="0px"
      left="50%"
      transform="translate(-50%, 0px)"
      background={navbarBg}
      border={navbarBorder}
      boxShadow={navbarShadow}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderRadius="15px"
      px="16px"
      py="22px"
      mx="auto"
      width="95%"
      maxW="95%"
    >
      <Flex w="100%" justifyContent={{ sm: "start", lg: "space-between" }}>
        <Flex style={{backgroundColor: "#1A202C", borderRadius:10}}>
          {brand}

        </Flex>
        
        <Box ms={{ base: "auto", lg: "0px" }} display={{ lg: "none" }}>
          <SidebarResponsive
            logoText={props.logoText}
            secondary={props.secondary}
            routes={routes}
            // logo={logo}
            {...rest}
          />
        </Box>
        <Flex justifyContent={{ sm: "start", lg: "space-between" }} mt={"-10"}>

          {linksAuth}
          <Link href="https://www.creative-tim.com/product/purity-ui-dashboard-pro">
            <Button
              bg={bgButton}
              color={colorButton}
              fontSize="xs"
              variant="no-hover"
              borderRadius="7px"
              px="30px"
              ml="30px"
              mt="33px"
              display={{
                sm: "none",
                lg: "none",
              }}
            >
              Sign Up
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
