
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  Spinner,
  Checkbox
} from "@chakra-ui/react";


import { NavLink, useLocation } from "react-router-dom";
// Assets
import basic from "assets/img/login.jpg";
import React from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { Redirect } from "react-router-dom";
import { useState } from "react";
import { Auth } from 'aws-amplify';


function SignUpClient() {

  // Auth variables
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [action, setAction] = useState(undefined);
  const [vCode, setVCode] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [companyContactEmail, setCompanyContactEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [companyInfoComplete,setCompanyInfoComplete] = useState(false);
  const [terms,setTerms] = useState(false);




  const titleColor = useColorModeValue("blue.400", "blue.400");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");

  async function attemptSignUp() {
    try {
      setIsLoading(true);
      const trimmedUsername = email.trim();
      const trimmedPassword = password.trim();
      const trimmedName = name.trim();
      const { user } = await Auth.signUp({
        username: trimmedUsername,
        password: trimmedPassword,
        attributes: {
          name: trimmedName,
        },
      });
      console.log(user);
      // Save new user
      var url = "https://fk5gi285gf.execute-api.us-east-1.amazonaws.com/v1/newuser"
      const data = {
        userInfo: {
          name: name,
          createdAt: new Date(),
          email: email,
          termsAccepted: {
            status: true,
            date: new Date()
          },
          jobTitle: jobTitle,
          role: "owner"
        },
        companyInfo: {
          companyOwner: email,
          companyName: companyName,
          companyIndustry: companyIndustry,
          companyContactEmail: companyContactEmail,
          companyPhone: companyPhone,
          credits: 20,
          address: address,
          address2: address2,
          city: city,
          state: state,
          zip: zip,
        }
      };
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      
      setIsLoading(false);
      setAction("confirm");
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      console.log("error signing up:", error);
    }
  }
  
  async function confirmSignUp() {
    try {
      setIsLoading(true);
      const trimmedCode = vCode.trim();
      const trimmedEmail = email.trim();
      await Auth.confirmSignUp(trimmedEmail, trimmedCode);
      setAction("complete");
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      console.log("error confirming sign up", error);
    }
  }

  return (
    
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      {action == "complete" ? <Redirect from={`/`} to="auth/authentication/sign-in/client" /> : 
      <>
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "70vh" }}
        w={{ md: "calc(100vw - 0px)" }}
        borderRadius={{ md: "0px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={basic}
        bgPosition="top"
        bgSize="cover"
        mx={{ md: "auto" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="70px"
      >
        
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        {!companyInfoComplete ?
        <Flex
        direction="column"
        w="445px"
        background="transparent"
        borderRadius="15px"
        p="40px"
        mx={{ base: "100px" }}
        bg={bgColor}
        boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
      >
        <Text
          fontSize="xl"
          color={textColor}
          fontWeight="bold"
          textAlign="center"
          mb="22px"
        >
          Company Info
        </Text>
        
        <FormControl>
        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          Company Name
        </FormLabel>
        <Input
          id="company-name-field"
          fontSize="sm"
          ms="4px"
          borderRadius="15px"
          type="text"
          placeholder="Your company name"
          mb="24px"
          size="lg"
          value={companyName}
          onChange={(d) => {
            setCompanyName(d.target.value);
          }}
        />

        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          Company Industry
        </FormLabel>
        <Input
          id="company-industry-field"
          fontSize="sm"
          ms="4px"
          borderRadius="15px"
          type="text"
          placeholder="Your company industry"
          mb="24px"
          size="lg"
          value={companyIndustry}
          onChange={(d) => {
            setCompanyIndustry(d.target.value);
          }}
        />

        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          Company Contact Email
        </FormLabel>
        <Input
          id="company-contact-email-field"
          fontSize="sm"
          ms="4px"
          borderRadius="15px"
          type="email"
          placeholder="Your company contact email"
          mb="24px"
          size="lg"
          value={companyContactEmail}
          onChange={(d) => {
            setCompanyContactEmail(d.target.value);
          }}
        />

        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          Company Phone
        </FormLabel>
        <Input
          id="company-contact-email-field"
          fontSize="sm"
          ms="4px"
          borderRadius="15px"
          type="email"
          placeholder="Your company contact email"
          mb="24px"
          size="lg"
          value={companyPhone}
          onChange={(d) => {
            setCompanyPhone(d.target.value);
          }}
        />
        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          Address
        </FormLabel>
        <Input
          id="address-field"
          fontSize="sm"
          ms="4px"
          borderRadius="15px"
          type="text"
          placeholder="Your address"
          mb="24px"
          size="lg"
          value={address}
          onChange={(d) => {
            setAddress(d.target.value);
          }}
        />

        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          Address 2
        </FormLabel>
        <Input
          id="address-2-field"
          fontSize="sm"
          ms="4px"
          borderRadius="15px"
          type="text"
          placeholder="Your address 2"
          mb="24px"
          size="lg"
          value={address2}
          onChange={(d) => {
            setAddress2(d.target.value);
          }}
        />

        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          City
        </FormLabel>
        <Input
          id="city-field"
          fontSize="sm"
          ms="4px"
          borderRadius="15px"
          type="text"
          placeholder="Your city"
          mb="24px"
          size="lg"
          value={city}
          onChange={(d) => {
            setCity(d.target.value);
          }}
        />

        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          State
        </FormLabel>
        <Input
          id="state-field"
          fontSize="sm"
          ms="4px"
          borderRadius="15px"
          type="text"
          placeholder="Your state"
          mb="24px"
          size="lg"
          value={state}
          onChange={(d) => {
            setState(d.target.value);
          }}
        />

        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          Zip
        </FormLabel>
        <Input
          id="zip-field"
          fontSize="sm"
          ms="4px"
          borderRadius="15px"
          type="text"
          placeholder="Your zip code"
          mb="24px"
          size="lg"
          value={zip}
          onChange={(d) => {
            setZip(d.target.value);
          }}
        />
        <Button
              type="submit"
              fontSize="sm"
              colorScheme={'blue'}
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={()=>{setCompanyInfoComplete(true)}}
              isDisabled={!companyName || !companyIndustry || !companyContactEmail || !companyPhone || !address || !city || !state || !zip}


            >
            {"NEXT"} 

            </Button>
        </FormControl>
        <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColor} fontWeight="medium">
              Already have an account?
              <NavLink to="/auth/authentication/sign-in/client">
                <Link 
                  color={titleColor}
                  as="span"
                  ms="5px"
                  href={`${process.env.PUBLIC_URL}/#/auth/authentication/sign-in/client`}
                  fontWeight="bold"
                >
                  Sign In
                </Link>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
        :
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Text
            fontSize="xl"
            color={textColor}
            fontWeight="bold"
            textAlign="center"
            mb="22px"
          >
            Register
          </Text>
          
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Name
            </FormLabel>
            <Input
              id="name-field"
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="text"
              placeholder="Your full name"
              mb="24px"
              size="lg"
              value={name}
              onChange={(d) => {setName(d.target.value)}}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Job Title
            </FormLabel>
            <Input
              id="job-title-field"
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="text"
              placeholder="Your job title"
              mb="24px"
              size="lg"
              value={jobTitle}
              onChange={(d) => {
                setJobTitle(d.target.value);
              }}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email
            </FormLabel>
            <Input
              id="email-field"
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="email"
              placeholder="Your email address"
              mb="24px"
              size="lg"
              value={email}
              onChange={(d) => {setEmail(d.target.value)}}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              id="password-field"
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="password"
              placeholder="Your password"
              mb="24px"
              size="lg"
              value={password}
              onChange={(d) => {setPassword(d.target.value)}}
            />
            {action == "confirm" ? 
            <>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Verification Code
            </FormLabel>
            <Input
            id="verification-field"
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="text"
              placeholder="Your Verification Code"
              mb="24px"
              size="lg"
              onChange={(d) => {setVCode(d.target.value)}}
            />

            {error && (
              <Flex w='100%' justifyContent={'center'} alignItems={'center'}>
              <Text color="red.500" fontSize="sm" paddingBottom={5}>
                {error}
              </Text>
              </Flex>
            )}
            <Checkbox size="sm" colorScheme="blue" defaultChecked={false} mb="16px" onChange={(event) => setTerms(event.target.checked)}>
              I agree to the{" "}
              <Link href="/terms-of-service" color={titleColor}>
                terms and services
              </Link>
            </Checkbox>
            <Button
              type="submit"
              fontSize="sm"
              colorScheme={'blue'}
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={()=>{confirmSignUp()}}
              isDisabled={!name || !email || !password || !vCode || !terms}

            >
            {isLoading ? <Spinner size="sm" /> : "CONFIRM"} 

            </Button>
            </>
            :
            <>
            {error && (
              <Flex w='100%' justifyContent={'center'} alignItems={'center'}>
              <Text color="red.500" fontSize="sm" paddingBottom={5}>
                {error}
              </Text>
              </Flex>
            )}
            
            <Button
              type="submit"
              fontSize="sm"
              fontWeight="bold"
              colorScheme={'blue'}
              w="100%"
              h="45"
              mb="24px"
              onClick={()=>{attemptSignUp()}}
              isDisabled={!name || !email || !password}

            >
                            {isLoading ? <Spinner size="sm" /> : "SIGN UP"} 

            </Button>
            </>
            } 
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColor} fontWeight="medium">
              Already have an account?
              <NavLink to="/auth/authentication/sign-in/client">
                <Link 
                  color={titleColor}
                  as="span"
                  ms="5px"
                  href={`${process.env.PUBLIC_URL}/#/auth/authentication/sign-in/client`}
                  fontWeight="bold"
                >
                  Sign In
                </Link>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
        }
      </Flex>
      </>}
    </Flex>
  );

}
  

export default SignUpClient;


