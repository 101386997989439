import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Badge,
  Flex,
  Text,
  Button,
  UnorderedList,
  ListItem,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useColorModeValue
} from "@chakra-ui/react";
import { SocketContext } from "../../../components/Layout/MainPanel";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { BsDownload, BsEye, BsStar, BsSearch } from "react-icons/bs";
import { useLocation } from "react-router-dom";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ListCandidates({ selectedJob }) {
  const socket = useContext(SocketContext);
  const [resumes, setResumes] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [refresh,setRefresh] = useState(undefined);
  let bgColor = useColorModeValue("#EDF2F7", "#313843");

  useEffect(() => {
    socket.on("getCompanyCredits", () => {
      socket.emit("getCandidates", selectedJob._id);
      console.log('Resume Completed message')
    });
    socket.on("candidates", (resumes) => {
      setResumes(resumes.scannedCandidates);
      if (resumes.scannedCandidates.length > 0 && selectedJob._id == resumes.scannedCandidates[0].jobId){
      setSelectedResume(resumes.scannedCandidates[0]);
      }
    });
  
  }, [socket]);

  useEffect(() => {
    console.log('getting candidates')
    socket.emit("getCandidates", selectedJob._id);
  }, [selectedJob]);

  const handleResumeClick = (resume) => {
    console.log(resume)
    if (selectedResume === resume) {
      //setShowModal(true);
    } else {
      setSelectedResume(resume);
      setShowModal(false);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function downloadResume() {
    window.location.href = selectedResume.Location;
  }

  function toggleFavorite() {
    // add code to toggle the selected resume as a favorite
  }



  return (
    <Flex >
      <Box w="35%" p="2">
      <InputGroup mb={3}>
        <InputLeftElement
          pointerEvents="none"
          children={<Icon as={BsSearch} color="gray.300" />}
        />
        <Input
          type="text"
          placeholder="Search candidates..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </InputGroup>
        <Text fontSize="lg" fontWeight="bold" mb="2">
          Candidates
        </Text>
        
        {resumes
          .filter((resume) =>
            resume.candidateName.toLowerCase().includes(searchText.toLowerCase())
          ).map((resume) => (
          <Box
            key={resume._id}
            onClick={() => handleResumeClick(resume)}
            cursor="pointer"
            p="2"
            style={{borderRadius:10,backgroundColor:bgColor}}
            borderRadius="md"
            mb="4"
          >
            <Flex flexDir={'row'} justifyContent={'space-between'} pl={5} pr={5}>
            <Text fontWeight={"medium"}>{resume.candidateName}</Text> 
            <Text style={{color: resume.matchScore >= 75 ? 'green' : resume.matchScore >= 60 ? 'orange' : 'red'}}>
                    {resume.matchScore}% 
            </Text>
            </Flex>
          </Box>
        ))}
          
      </Box>
      <Box w="100%" borderRadius={10}>
        <Flex justifyContent="flex-end" >
          <Button
            mr={2}
            variant="ghost"
            onClick={downloadResume}
            aria-label="Download Resume"
          >
            <Icon as={BsDownload} fontSize={'2xl'}/>
          </Button>
          <Button
            mr={2}
            variant="ghost"
            onClick={() => setShowModal(true)}
            aria-label="View Resume"
          >
            <Icon as={BsEye} fontSize={'2xl'}/>
          </Button>
          <Button
            variant="ghost"
            onClick={toggleFavorite}
            aria-label="Add to Favorites"
          >
            <Icon as={BsStar} fontSize={'2xl'}/>
          </Button>
          </Flex>
      {selectedResume && (
        <Flex onClick={() => handleResumeClick(selectedResume)} m={5} justifyContent={'center'} flexDirection={'column'} >
          
        <Flex w="100%" alignItems="center" justifyContent="space-between" >
            <Flex flexDirection="column">
              <Text fontSize="md" fontWeight="bold" mb="1">
                Name:
              </Text>
              <Text fontSize="md" mb="2">
                {selectedResume.candidateName}
              </Text>
              <Text fontSize="md" fontWeight="bold" mb="1">
                Email:
              </Text>
              <Text fontSize="md" mb="2">
                {selectedResume.candidateEmail}
              </Text>
              <Text fontSize="md" fontWeight="bold" mb="1">
                Phone Number:
              </Text>
              <Text fontSize="md" mb="2">
                {selectedResume.candidatePhoneNumber}
              </Text>
            </Flex>
            <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" border="2px solid gray" borderRadius={5} p={4} mb={4} fontSize="4xl" fontWeight="bold">
                  
                  <Text style={{color: selectedResume.matchScore >= 75 ? 'green' : selectedResume.matchScore >= 60 ? 'orange' : 'red'}}>
                    {selectedResume.matchScore}%
                  </Text>
                  <Text fontSize="md" fontWeight="bold" mb="1">
                    {selectedResume.matchDescriptionScore}
                  </Text>
                  
                  
            </Box>
          </Flex>
        
        <Text fontSize="md" fontWeight="bold" mb="1">
          Candidate Summary:
        </Text>
        <Text fontSize="md" mb="2">
          {selectedResume.matchScoreExplanation}
        </Text>
        { selectedResume.estimatedSalary ?
        <>
        <Text fontSize="md" fontWeight="bold" mb="1">
          Estimated Salary:
        </Text>
        <Text fontSize="md" mb="2">
          {selectedResume.estimatedSalary}
        </Text>
        
        <Text fontSize="md" fontWeight="bold" mb="1">
          Salary Rationale:
        </Text>
        <Text fontSize="md" mb="2">
          {selectedResume.estimatedSalaryExplanation}
        </Text>
        </>
        : 
        null
        }
        <Text fontSize="md" fontWeight="bold" mb="1">
              Skills:
            </Text>
            <Flex flexWrap="wrap" mb="2">
              {selectedResume.candidateSkills.map((item, index) => (
                <Badge key={index} mr="2" mb="2">
                  {item}
                </Badge>
              ))}
            </Flex>
            <Text fontSize="md" fontWeight="bold" mb="1">
              Education:
            </Text>
            <UnorderedList mb="2">
            {selectedResume.candidateEducation.map((item, index) => (
             <ListItem key={index} fontSize="md" ml="4">
                  {item.organization || "Organization Not Specified"} - {item.attained || "Not Specified"}
             </ListItem>
            ))}
            </UnorderedList>

            <Text fontSize="md" fontWeight="bold" mb="1">
              Licenses & Certifications:
            </Text>
            <UnorderedList mb="2">
            { selectedResume.candidateLicense
            ?
            selectedResume.candidateLicense.map((item, index) => (
             <ListItem key={index} fontSize="md" ml="4">
                  {item}
             </ListItem>
            ))
            :null}
            </UnorderedList>

            <Text fontSize="md" fontWeight="bold" mb="1">
              Work History:
            </Text>
            <UnorderedList mb="2">
            {selectedResume.candidateWorkExperience.map((item, index) => (
             <ListItem key={index} fontSize="md" ml="4">
                  {item.jobTitle || "Job Title Not Specified"} - {item.yearsInRole >=1 ? `${item.yearsInRole} Years` : item.yearsInRole < 1 ? `${(item.yearsInRole * 12)} Months` : "Not Specified"}
             </ListItem>
            ))}
            </UnorderedList>
      
      </Flex>
      
      )}
      {showModal && (
        <Modal isOpen={showModal} onClose={() => setShowModal(false)} >
          <ModalOverlay/>
          <ModalContent maxW={"60%"} bg={'#000'} >
          <ModalHeader>
              <Flex w={"100%"} justifyContent={'space-between'} alignItems={"center"} flexDirection={'row'} mt={5}>
                  <Text ml={5} color={'#FFF'}>
                      Page {pageNumber} of {numPages}
                  </Text>
                  <Flex w={"30%"} justifyContent={'space-evenly'} alignItems={"center"} flexDirection={'row'}>
                  <Button onClick={previousPage}>
                        Previous
                    </Button>
                    <Button onClick={nextPage}>Next</Button>
                </Flex>
            </Flex>
          </ModalHeader>
            
            <ModalBody >
              <Flex onClick={() => handleResumeClick(selectedResume)} m={5} justifyContent={'center'} >
                  <Document
                  file={selectedResume.Location}
                  onLoadSuccess={onDocumentLoadSuccess}
                  >
                  <Page pageNumber={pageNumber} renderTextLayer={false} width={800} height={1000}/>
                  </Document>
              </Flex>
              
            </ModalBody>
            
          </ModalContent>
        </Modal>
      )}
    </Box>
  </Flex>
);
}



export default ListCandidates;



