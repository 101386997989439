import {
  Flex,
  Icon,
  Text,
  useColorModeValue,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,Table, Tbody, Tr, Td,TableContainer
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import React, { useRef, useState, useContext, useEffect } from "react";
import { SocketContext } from "../../../components/Layout/MainPanel";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TransactionRow from "./TransactionRow";
import ResumeUpload from "./ResumeUpload";
import JobDetails from "./JobDetails";
import ListResumes from "./ListResumes";
import ListCandidates from "./ListCandidates";
import { FaArchive, FaArrowLeft } from "react-icons/fa";
import { globalStyles } from "./../../../theme/styles";

const Jobs = () => {
  const socket = useContext(SocketContext);
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const primary = globalStyles.colors.blue[500];
  const [jobs, setJobs] = useState([]);
  const [arjobs, setarJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(undefined);
  const [tabIndex, setTabIndex] = useState(0)
  const [jobsTabIndex, setJobsTabIndex] = useState(0)

  useEffect(() => {
    const pathname = window.location.hash; // Get the pathname from the URL
    console.log(pathname)
    var jobId = pathname.split("/")[4]; // Extract the jobId from the pathname
    var jTab = pathname.split("/")[3];
    if (jobId) {
      jobId = jobId.split("?")[0]; // Extract the jobId from the pathname

    }
    console.log(jobId)
    if (jobId && jTab == 0) {
      // Filter the data array by the jobId and set that item to selectedJob
      const job = jobs.filter((item) => item._id === jobId)[0];
      console.log(job)
      setSelectedJob(job);
      const searchParams = new URLSearchParams(location.search);
      const tab = searchParams.get("tab");
      console.log(tab)
      setTabIndex(parseInt(tab))
    }

  },[jobs])

  useEffect(() => {
    const pathname = window.location.hash; // Get the pathname from the URL
    console.log(pathname)
    var jobId = pathname.split("/")[4]; // Extract the jobId from the pathname
    var jTab = pathname.split("/")[3];
    if (jobId) {
      jobId = jobId.split("?")[0]; // Extract the jobId from the pathname

    }
    console.log(jobId)
    if (jobId && jTab == 1) {
      // Filter the data array by the jobId and set that item to selectedJob
      const job = arjobs.filter((item) => item._id === jobId)[0];
      console.log(job)
      setSelectedJob(job);
      const searchParams = new URLSearchParams(location.search);
      const tab = searchParams.get("tab");
      console.log(tab)
      setTabIndex(parseInt(tab))
    }

  },[arjobs])

  useEffect(() => {
    if (socket) {
      getJobs();
      getArchivedJobs();
      socket.on("myJobs", (data) => {
        console.log(data);
        setJobs(data);
        
      });

      socket.on("myArchivedJobs", (data) => {
        console.log(data);
        setarJobs(data);
        
      });
    }
  }, [socket]);
  

  const location = useLocation();

 

  const getJobs = async () => {
    socket.emit("myJobs");
  };

  const getArchivedJobs = async () => {
    socket.emit("myArchivedJobs");
  };

  const handleTabsChange = (index) => {
    setTabIndex(index)
    window.history.pushState(null, null, `/#/admin/jobs/${jobsTabIndex}/${selectedJob._id}?tab=${index}`);
  }

  const handleJobsTabsChange = (index) => {
    setJobsTabIndex(index)
    window.history.pushState(null, null, `/#/admin/jobs/${index}`);
  }

  const handleBackClick = () => {
    setSelectedJob(undefined);
    // Remove the _id of the selected job from the URL path
    window.history.pushState(null, null, `/#/admin/jobs/${jobsTabIndex}`);
  };

  const handleArchiveClick = () => {
    let val = selectedJob.archived ? false : true;
    socket.emit('archive',({jobId: selectedJob._id, value: val}))
  
    // Create an updatedSelectedJob object with the new archived value
    const updatedSelectedJob = {
      ...selectedJob,
      archived: val
    };
  
    if (val){
      // Remove the selectedJob from the jobs array
      const updatedJobs = jobs.filter((job) => job._id !== selectedJob._id);
      setJobs(updatedJobs);
  
      // Add the updatedSelectedJob to the arJobs array
      const updatedArJobs = [...arjobs, updatedSelectedJob];
      setarJobs(updatedArJobs);
    } else {
      // Remove the selectedJob from the arJobs array
      const updatedArJobs = arjobs.filter((job) => job._id !== selectedJob._id);
      setarJobs(updatedArJobs);
  
      // Add the updatedSelectedJob to the jobs array
      const updatedActiveJobs = [...jobs, updatedSelectedJob];
      setJobs(updatedActiveJobs);
    }
  };

  const handleSelectedJobEdit = (editedJob) => {
    setSelectedJob(editedJob);
  };

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "125px", lg: "105px" }}
    >
      <Card my="24px" ms={{ lg: "24px" }}>
        <CardHeader mb="12px">
          <Flex direction="column" w="100%">
            {selectedJob ? (
              <>
              <Flex justifyContent={'space-between'}>
                  <Button
                    leftIcon={<FaArrowLeft />}
                    colorScheme="blue"
                    onClick={handleBackClick}
                    mb="12px"
                    w={100}
                  >
                    Back
                  </Button>
                  <Button
                    leftIcon={<FaArchive />}
                    colorScheme="blue"
                    onClick={handleArchiveClick}
                    mb="12px"
                    w={150}
                  >
                    {selectedJob.archived ? "Undo Archive" : "Archive"}
                    
                  </Button>
                </Flex>
                <Flex
                  direction={{ sm: "column", lg: "row" }}
                  justify={{ sm: "center", lg: "space-between" }}
                  align={{ sm: "center" }}
                  w="100%"
                  my={{ md: "12px" }}
                >
                  <Text
                    color={textColor}
                    fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
                    fontWeight="bold"
                  >
                    {selectedJob.jobTitle}
                  </Text>
                </Flex>
                
              </>
            ) : (
              <Flex
                direction={{ sm: "column", lg: "row" }}
                justify={{ sm: "center", lg: "space-between" }}
                align={{ sm: "center" }}
                w="100%"
                my={{ md: "12px" }}
              >
                <Text
                  color={textColor}
                  fontSize={{ sm: "lg", md: "xl", lg: "lg" }}
                  fontWeight="bold"
                >
                  Your Jobs
                </Text>
              </Flex>
            )}

            {selectedJob && (
              <Flex
                direction="column"
                w="100%"
                my={{ md: "12px" }}
                textAlign={{ sm: "center", md: "left" }}
              >
                <Tabs index={tabIndex} onChange={handleTabsChange}>
                  <TabList borderRadius={10} >
                    <Tab>Candidates</Tab>
                    <Tab>Job Details</Tab>
                    <Tab>Upload Resumes</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel >
                      {/* Content for Candidates tab */}
                      <ListCandidates selectedJob={selectedJob} />
                    </TabPanel>
                    <TabPanel>
                      {/* Content for Job Details tab */}
                      <JobDetails selectedJob={selectedJob} handleSelectedJobEdit={handleSelectedJobEdit}/>
                    </TabPanel>
                    <TabPanel>
                      {/* Content for Upload Resumes tab */}
                      <ResumeUpload selectedJob={selectedJob}/>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Flex>
            )}

            {!selectedJob && (
              <CardBody>
                <Flex direction="column" w="100%">
                  <Tabs index={jobsTabIndex} onChange={handleJobsTabsChange}>
                    <TabList borderRadius={10} >
                      <Tab>Active Jobs</Tab>
                      <Tab>Archived Jobs</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel >
                      {jobs.map((row) => {
                        return (
                          <Stack
                            key={row.id}
                            onClick={() => {
                              console.log(row);
                              setSelectedJob(row);
                              window.history.pushState(null, null, `/#/admin/jobs/${jobsTabIndex}/${row._id}?tab=0`);
                            }}
                          >
                            <TransactionRow
                              name={row.jobTitle}
                              scans={row.scans ? row.scans : 0}
                              matches={row.matches}
                              stats={row.stats}
                            />
                          </Stack>
                        );
                      })}
                        
                      </TabPanel>
                      <TabPanel>

                      {arjobs.map((row) => {
                        return (
                          <Stack
                            key={row.id}
                            onClick={() => {
                              console.log(row);
                              setSelectedJob(row);
                              window.history.pushState(null, null, `/#/admin/jobs/${jobsTabIndex}/${row._id}?tab=0`);
                            }}
                          >
                            <TransactionRow
                              name={row.jobTitle}
                              scans={row.scans ? row.scans : 0}
                              matches={row.matches}
                              stats={row.stats}
                            />
                          </Stack>
                        );
                      })}
                        
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                
                
                  
                </Flex>
              </CardBody>
            )}
          </Flex>
        </CardHeader>
      </Card>
    </Flex>
  );
};

export default Jobs;

