import { useState, useEffect, useMemo } from "react";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { Amplify, Auth,Hub} from 'aws-amplify';
import { Spinner } from '@chakra-ui/react'
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);



export default function App() {

    const [user, setUser] = useState('initial');

    const getUser = async() => {
        try{
          var userData = await Auth.currentAuthenticatedUser({bypassCache:true});
          setUser(userData);
        } catch (e) {
          setUser('noUser');
        }
      }
    
      useEffect(() => {
        getUser();
      }, [])
    
      useEffect(() => {
        const listener = (data) => {
          if(data.payload.event === 'signIn' || data.payload.event === 'signOut'){
            getUser();
          }
        }
        Hub.listen('auth',listener);
        return () => Hub.remove('auth',listener);
      }, [])

    return (
        user == "initial" ?  
        <Spinner /> :
        user == "noUser" ? 
        <Redirect from={`/`} to="auth/authentication/sign-up/basic" /> : 
        <Redirect from={`/`} to="/admin/dashboard" />
        );
    
};

