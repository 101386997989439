import React, { useState, useEffect,useContext } from 'react';
import { Auth } from 'aws-amplify';
import Messages from './messages';
import MessageInput from './input';
import { SocketContext } from '../../components/Layout/MainPanel'
import * as DOMPurify from 'dompurify';
import { BsChevronRight,BsChevronDown,BsFillCloudArrowDownFill } from "react-icons/bs";
import { CgShapeCircle } from "react-icons/cg";

import {
    Box,
    Badge,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    Textarea,
    Icon,
    Image,
    Stack,
    useDisclosure,
    List,
    ListItem,
    ListIcon,
    Avatar,
    useColorModeValue,
    Wrap,
    WrapItem
  } from '@chakra-ui/react';




function ChatUi() {

  const textColor = useColorModeValue("gray.700", "white");
  const [messages, setMessages] = useState([]);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState(undefined);
  const [recEmail, setRecEmail] = useState('');
  const [name, setName] = useState(undefined);
  const socket = useContext(SocketContext);
  const [selectedChat, setSelectedChat] = useState(undefined);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chats, setChats] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isTyping, setIsTyping] = useState(null);
  const [userTyping, setUserTyping] = useState(null);
  const [file, setFile] = useState(null);
  const [numMessages, setNumMessages] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [reply, setReply] = useState(false);
  const [edit, setEdit] = useState(false);
  const [menuSelection,setMenuSelection] = useState(false);
  

  

  useEffect(() => {
    return () => {
      // Clear the typing timeout when the component unmounts to avoid a memory leak
      clearTimeout(typingTimeout);
    };
  }, [typingTimeout]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setIsTyping(false);
      return;
    }
    // If the user was already typing, clear the previous timeout
    clearTimeout(typingTimeout);

    setIsTyping(true)

    // Set a timeout to hide the indicator after a certain amount of time
    setTypingTimeout(setTimeout(() => {
     setIsTyping(false)
    }, 1000)); // The timeout duration can be adjusted as needed
  };

  useEffect(() => {
    if (isTyping) {
      socket.emit('typing', {'status':true, 'email': email, 'name':name ,'conversation': selectedChat._id});
    } else if (isTyping === false) {
      socket.emit('typing', {'status':false, 'email': email, 'name':name,'conversation': selectedChat._id});
    }
  }, [isTyping]);


    

   

    async function getUserEmail() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const e = user.attributes.email;
            const n = user.attributes.name;
            setEmail(e)
            setName(n)
            return true;
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
      if (socket){
      socket.emit('chat', {});
      }
    }, [socket]);
    
      useEffect(() => {
      
        getUserEmail();
        
        
    }, []);

    useEffect(()=> {
      if (socket) {
      console.log(selectedChat)
      
      socket.on('moreMessages', (messages) => {
        
        setNumMessages(messages.num);

        setMessages((previousMessages) => [...messages.messages.reverse(),...previousMessages]);
        setLoadingMessages(false)
        
      });
    }
    }, [socket])

    
    useEffect(() => {
        try{
            socket.on('message', (msg) => {
                console.log(msg)
                if (userTyping) {
                  setUserTyping(prevState => {
                    return {
                      ...prevState,
                      status: !prevState.status
                    }
                  })

                }
                
                setMessages((previous) => [...previous, msg]);
                let readReceipt = {
                  messageId : msg._id,
                  messageSender: msg.sender,
                  conversation: msg.conversation,
                  readReceipt : {email:email,avatarUrl:'https://bit.ly/tariq-drm'}
                }
                socket.emit("readReceipt", readReceipt)
              });

              socket.on('editedMessage', (msg) => {
                console.log(msg);
                
                
                setMessages((previous) => {
                  let index = previous.length - 1;
                  while (index >= 0) {
                    console.log(previous[index]._id,msg._id)
                    if (previous[index]._id === msg._id) {
                      previous[index].message = msg.message;
                      break;
                    }
                    index--;
                  }
                  return [...previous]

                });
              });

              socket.on('typing', (msg) => {
                console.log(msg)
                setUserTyping(msg)
                
              });
              

            socket.on('conversations', (conversations) => {
                //console.log(conversations);
                for (let chat of conversations){
                    let mems = chat.members.filter(member => member.email !== email);
                    chat.members = mems;  
                }
                setChats(conversations)
              });

              

            socket.on('uploadProgress', (progress) => {
              console.log(`Upload progress: ${progress}%`);
            });
              
            socket.on('lastMessages', (messages) => {
                setNumMessages(messages.num)
                setMessages(messages.messages.reverse());

                messages = messages.messages;
                //console.log(messages[messages.length - 1]._id)
                //console.log(messages[messages.length - 1].conversation)
                socket.emit('read_message', {
                    conversationId: messages[messages.length - 1].conversation,
                    messageId: messages[messages.length - 1]._id,
                    userEmail: email
                  });
                let readReceipt = {
                  messageId : messages[messages.length - 1]._id,
                  messageSender: messages[messages.length - 1].sender,
                  conversation: messages[messages.length - 1].conversation,
                  readReceipt : {email:email,avatarUrl:'https://bit.ly/tariq-drm'}
                }
                socket.emit("readReceipt", readReceipt)
              });

              socket.on('readReceipt', (msg) => {
                setMessages((previous) => {
                  // Get a copy of the last message
                  let lastMessage = {...previous[previous.length - 1]};
              
                  // Check if the read receipt is not already present in the readBy array
                  if (!lastMessage.readBy.find((readReceipt) => readReceipt.email === msg.email)) {
                    // Add the read receipt to the readBy array
                    lastMessage.readBy.push(msg);
              
                    // Update the messages state variable with the updated messages
                    return [...previous.slice(0, -1), lastMessage];
                  }
              
                  // Return the previous messages if the read receipt is already present in the readBy array
                  return previous;
                });
              });
              
              
            socket.on('messageOther',(msg) => {
              console.log(selectedChat?._id)
              setChats((prevChats) => {
                // Find the chat that the message belongs to
                const chatIndex = prevChats.findIndex((chat) => chat._id === msg.conversation);
                if (chatIndex === -1) {console.log("not found"); return prevChats;}  // If the chat wasn't found, return the current chats state
            
                // Create a copy of the chat and update the lastMessage field with the new message data
                const chat = { ...prevChats[chatIndex] };
                chat.lastMessage = {
                  senderName: msg.senderName,
                  message: msg.message,
                  timestamp: msg.timestamp
                };
                
                  chat.unreadMessages = chat.unreadMessages + 1;
                
            
                // Remove the chat from the chats state array
                let updatedChats = [...prevChats.slice(0, chatIndex), ...prevChats.slice(chatIndex + 1)];
                // Add the updated chat to the beginning of the chats state array
                updatedChats.unshift(chat);
                return updatedChats;
              });

            });

            socket.on('files',(files) => {
              setFiles(files)
            });

            socket.on('images',(images) => {
              setImages(images)
            });
          


            socket.on('conversation created', (conversationId) => {
                console.log(`Conversation created with ID: ${conversationId}`);
                // handle event data as needed
              });
        } catch (e) {
            console.log(e)
        }
        
    }, [socket]);

    // Function to handle the message input field
    const handleChange = (event) => {
        setMessage(event.target.value);
    };

    const handleFileSelect = (e) => {
      const file = e.target.files[0];
      console.log(file);
      const chunkSize = 10 * 1024; // 10KB chunk size
      let offset = 0;
      const fileData = {
        name: file.name,
        type: file.type,
        size: file.size,
        data: null,
        'sender': email,
        'senderAvatar': 'https://bit.ly/tariq-drm',
        'senderName': name,
        'conversation':selectedChat._id,
        'readBy':[{email: email, avatarUrl:'https://bit.ly/tariq-drm'}],
        'timestamp':new Date()
      };

      while (offset < file.size) {
        const chunk = file.slice(offset, offset + chunkSize);
        socket.emit('sendFile', chunk, offset, file.size,fileData);
        offset += chunkSize;
      }

      
    };

    const handleLoadMoreMessages = () => {
      
      console.log('loading more messages')
      console.log({conversationId : selectedChat._id, skipNumber: messages.length})
      setLoadingMessages(true)
      socket.emit('loadMoreMessages', {conversationId : selectedChat._id, skipNumber: messages.length});
    }

    const handleSetReply = (msg) => {
      setReply(msg)
      console.log(msg)
    }

    const handleSendEdit = (msg) => {
      let newEdit = edit;
      edit.message = msg;
      console.log(newEdit)
      socket.emit('edit_message', newEdit)
      setEdit(false)
    }

    // Function to handle the send button
    const handleSend = () => {
        
        let m = {
            'sender': email,
            'senderAvatar': 'https://bit.ly/tariq-drm',
            'senderName': name,
            'conversation':selectedChat._id,
            'type':'text',
            'readBy':[{email: email, avatarUrl:'https://bit.ly/tariq-drm'}],
            'message':message,
            'reply':reply,
            'timestamp':new Date()
        }
        
        // Send the message to the Socket.io server
        socket.emit('message', m);

        
        

        setChats((prevChats) => {
          // Find the chat that the message belongs to
          const chatIndex = prevChats.findIndex((chat) => chat._id === m.conversation);
          if (chatIndex === -1) {console.log("not found"); return prevChats;}  // If the chat wasn't found, return the current chats state
      
          // Create a copy of the chat and update the lastMessage field with the new message data
          const chat = { ...prevChats[chatIndex] };
          chat.lastMessage = {
            senderName: m.senderName,
            message: m.message,
            timestamp: m.timestamp
          };
          
      
          // Remove the chat from the chats state array
          let updatedChats = [...prevChats.slice(0, chatIndex), ...prevChats.slice(chatIndex + 1)];
          // Add the updated chat to the beginning of the chats state array
          updatedChats.unshift(chat);
          return updatedChats;
        });
    
        if(reply){
          setReply(false)
        }
        // Clear the message input field
        setMessage('');
    };

    // Function to handle creating a new chat
    const createChat = () => {
        let members = [
            {
                "email":email,
                "name":name,
                "avatarUrl":"https://bit.ly/tariq-drm"
            },
            {
                "email":recEmail,
                "name": "John Doe",
                "avatarUrl":"https://bit.ly/tariq-drm"
            },
        ]
        if (email && recEmail) {
            socket.emit('create conversation', members);
        }
        // Clear the message input field
        setRecEmail('');
    };

    const setUnread = (chat) => {
        chat.unreadMessages = 0;
        setChats((prevChats) => (prevChats))
    };

    const formatChatName = (members) => {
      return members.length > 2 
      ?
      `${members[0].name.split(" ")[0]}, ${members[1].name.split(" ")[0]} & others`
      : 
      members.length > 1
      ?
      `${members[0].name}, ${members[1].name}`.substring(0,30)
      :
      members[0].name
    }
    const formatFileName = (fileName) => {
      fileName = decodeURIComponent(fileName);
      let firstSplit = fileName.split('/').pop()
      var final = firstSplit.split('.$plit.').pop()
      if (final.length < 15){
        var n = final
      } else {
        var n = final.substring(0, 15) + "..." + final.split('.').pop()
      }
      return n.toString()
    }



   

    return (
        <Box bg="#F7FAFC" minH="100%" p={5} pt={120} m={5} >
          
            
            <Flex>
                <Box width="20%" pr={4} display={['block', 'none', 'block']} >
                    <List cursor="pointer"
                    >
                    {chats.map(chat => (
                        <ListItem
                        _hover={{ backgroundColor: "#D0EACD" }}
                        cursor="pointer"
                        key={chat._id}
                        onClick={async() => {
                            if (selectedChat) {
                              socket.emit('leave', selectedChat._id);
                              
                              
                            }
                            setSelectedChat(chat);
                            socket.emit('join', chat._id);
                            setUnread(chat)

                        }}
                        fontWeight={selectedChat?._id === chat._id ? 'bold' : 'normal'}
                        backgroundColor={selectedChat?._id === chat._id ? '#D0EACD' : null}
                        mt={2}
                        mb={2}
                        borderRadius={10}
                        shadow='md'
                        borderBottomWidth={1}
                        p={2}
                        pl={5}
                        pr={5}
                        
                        
                        >
                        <Flex >
                          <Avatar src="https://bit.ly/tariq-drm" />
                          
                          <Flex pl={2} alignItems="flex-start" justifyContent={'center'} flexDir={'column'}>
                            <Box as="h4" fontSize="sm">
                            {formatChatName(chat.members)}
                              {chat.unreadMessages > 0 && (
                                <Badge rounded="full" fontSize="xs" fontWeight="bold" color="red.500" px={2}>
                                  <Text>{chat.unreadMessages}</Text>
                                </Badge>
                              )}
                            </Box>
                            
                            <Box color="gray.500" fontSize={"sm"} fontWeight={'normal'}>
                              <Text dangerouslySetInnerHTML={{ __html: `${chat.lastMessage.senderName.split(" ")[0]}: ${chat.lastMessage.message.substring(0, 30)}` }}></Text>
                            </Box>
                          </Flex>
                        </Flex>
                      </ListItem>
                        
                    ))}
                    </List>
                </Box>
                {selectedChat ?
                <>
                <Box width={['100%', '100%', "60%"]}>
                    <Stack spacing={4}>
                    {/* Add code to display messages here */}
                    <Messages 
                      messages={messages}
                      email={email}
                      userTyping={userTyping}
                      handleLoadMoreMessages={handleLoadMoreMessages}
                      selectedChat={selectedChat}
                      numMessages={numMessages}
                      loadingMessages={loadingMessages}
                      reply={reply}
                      handleSetReply={handleSetReply}
                      handleKeyDown={handleKeyDown}
                      edit={edit}
                      setEdit={setEdit}
                      handleSendEdit={handleSendEdit}
                      />
                        
                        <MessageInput
                                inputMessage={message}
                                setInputMessage={setMessage}
                                handleFileSelect={handleFileSelect}
                                handleSendMessage={handleSend}
                                handleKeyDown={handleKeyDown}
                                reply={reply}
                                handleSetReply={handleSetReply}
                            />
                    </Stack>
                </Box>
                <Flex width={['100%', '100%', "30%"]} display={['none', 'none','block']} ml={2} mr={2} h="67vh" mb={3} overflowY="scroll" flexDirection="column" p="3" sx={{
                  '&::-webkit-scrollbar': {
                  width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                  width: '6px'
                  },
                  '&::-webkit-scrollbar-thumb': {
                  background: '#01BE04',
                  borderRadius: '24px',
                  },
                }}>
                
                        <Flex
                          
                          _hover={{ backgroundColor: "#D0EACD" }}
                          cursor="pointer"
                          onClick={async() => {
                            if (menuSelection != "Contract Details") {
                              setMenuSelection("Contract Details")
                            } else {
                              setMenuSelection("")
                            }
                            
                          }}
                          justifyContent='space-between'
                          alignItems={'center'}
                          fontWeight={menuSelection === "Contract Details" ? 'bold' : 'normal'}
                          backgroundColor={menuSelection === "Contract Details" ? '#D0EACD' : null}
                          mt={2}
                          mb={2}
                          
                          borderRadius={10}
                          shadow='md'
                          borderWidth={1}
                          p={2}
                          pl={5}
                          pr={5}
                        >
                        
                          <Text fontWeight={'bold'} >Contract Details</Text>
                          <Icon as={menuSelection === "Contract Details" ? BsChevronDown : BsChevronRight}  w="20px" h="20px" />
                        
                      </Flex>

                      { menuSelection == "Contract Details" ? 
                      <>
                        <Flex
                        
                        alignItems="center"
                        minH="70px"
                        justifyContent="start"
                        //   mb="5px"
                      >
                        <Flex direction="column" align="center" h="100%" me="20px" >
                          <Icon
                            as={CgShapeCircle}
                            color="teal.300"
                            h={"30px"}
                            w={"30px"}
                            zIndex="1"
                            position="relative"
                          />
                          <Box w="2px" bg="gray.200" h="100%" minH='35px'></Box>
                        </Flex>
                        <Flex direction="column" justifyContent="flex-start" h="100%">
                          <Text fontSize="sm" color={textColor} fontWeight="bold">
                            Order received
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.400"
                            fontWeight="normal"
                            mb="14px"
                          >
                            22 DEC 7:20 AM
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" minH="70px" justifyContent="start">
                        <Flex direction="column" align="center" h="100%" me="20px">
                          <Icon
                            as={CgShapeCircle}
                            color="teal.300"
                            h={"30px"}
                            w={"30px"}
                            zIndex="1"
                            position="relative"
                          />
                          <Box w="2px" bg="gray.200" h="100%" minH='35px'></Box>
                        </Flex>
                        <Flex direction="column" justifyContent="flex-start" h="100%">
                          <Text fontSize="sm" color={textColor} fontWeight="bold">
                            Generate order id #1832412
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.400"
                            fontWeight="normal"
                            mb="14px"
                          >
                            22 DEC 7:21 AM
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" minH="70px" justifyContent="start">
                        <Flex direction="column" align="center" h="100%" me="20px">
                          <Icon
                            as={CgShapeCircle}
                            color="blue.400"
                            h={"30px"}
                            w={"30px"}
                            zIndex="1"
                            position="relative"
                          />
                          <Box w="2px" bg="gray.200" h="100%" minH='35px'></Box>
                        </Flex>
                        <Flex direction="column" justifyContent="flex-start" h="100%">
                          <Text fontSize="sm" color={textColor} fontWeight="bold">
                            Order transmited to courier
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.400"
                            fontWeight="normal"
                            mb="14px"
                          >
                            22 DEC 8:10 AM
                          </Text>
                        </Flex>
                      </Flex>
                      
                      <Flex alignItems="center" minH="70px" justifyContent="start">
                        <Flex direction="column" align="center" h="100%" me="20px">
                          <Icon
                            as={CgShapeCircle}
                            color="blue.400"
                            h={"30px"}
                            w={"30px"}
                            zIndex="1"
                            position="relative"
                          />
                          <Box w="2px"  h="100%" minH='35px'></Box>
                        </Flex>
                        <Flex direction="column" justifyContent="flex-start" h="100%">
                          <Text fontSize="sm" color={textColor} fontWeight="bold">
                            Order delivered
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.400"
                            fontWeight="normal"
                            mb="14px"
                          >
                            22 DEC 4:54 PM
                          </Text>
                        </Flex>
                      </Flex>
                      </>
                      :
                      null}

                      <Flex
                          
                          _hover={{ backgroundColor: "#D0EACD" }}
                          cursor="pointer"
                          onClick={async() => {
                            if (menuSelection != "Team Members") {
                              setMenuSelection("Team Members")
                            } else {
                              setMenuSelection("")
                            }
                          }}
                          justifyContent='space-between'
                          alignItems={'center'}
                          fontWeight={menuSelection === "Team Members" ? 'bold' : 'normal'}
                          backgroundColor={menuSelection === "Team Members" ? '#D0EACD' : null}
                          mt={2}
                          mb={2}
                          
                          borderRadius={10}
                          shadow='md'
                          borderWidth={1}
                          p={2}
                          pl={5}
                          pr={5}
                        >
                        
                          <Text fontWeight={'bold'} >Team Members</Text>
                          <Icon as={menuSelection === "Team Members" ? BsChevronDown : BsChevronRight}  w="20px" h="20px" />
                        
                      </Flex>
                      {menuSelection == "Team Members" ? 
                      <List cursor="pointer"
                      >
                      {selectedChat.members.map(member => (
                          <ListItem
                          with
                          _hover={{ backgroundColor: "#F1F1F1" }}
                          cursor="pointer"
                          onClick={async() => {
                              
  
                          }}
                          mt={2}
                          mb={2}
                          borderRadius={10}
                          shadow='none'
                          borderBottomWidth={0}
                          p={2}
                          pl={5}
                          pr={5}
                          
                          
                          >
                          <Flex >
                            <Avatar size='sm' src={member.avatarUrl} />
                            
                            <Flex pl={2} alignItems="flex-start" justifyContent={'center'} flexDir={'column'}>
                              <Box as="h4" fontSize="sm">
                              <Text>{member.name}</Text>
                              </Box>
                            </Flex>
                          </Flex>
                        </ListItem>
                          
                      ))}
                      </List>
                      :
                      null
                      }

                      <Flex
                          
                          _hover={{ backgroundColor: "#D0EACD" }}
                          cursor="pointer"
                          onClick={async() => {
                            if (menuSelection != "Saved Messages") {
                              setMenuSelection("Saved Messages")
                            } else {
                              setMenuSelection("")
                            }
                          }}
                          justifyContent='space-between'
                          alignItems={'center'}
                          fontWeight={menuSelection === "Saved Messages" ? 'bold' : 'normal'}
                          backgroundColor={menuSelection === "Saved Messages" ? '#D0EACD' : null}
                          mt={2}
                          mb={2}
                          
                          borderRadius={10}
                          shadow='md'
                          borderWidth={1}
                          p={2}
                          pl={5}
                          pr={5}
                        >
                        
                          <Text fontWeight={'bold'} >Saved Messages</Text>
                          <Icon as={menuSelection === "Saved Messages" ? BsChevronDown : BsChevronRight}  w="20px" h="20px" />
                        
                      </Flex>

                      <Flex
                          
                          _hover={{ backgroundColor: "#D0EACD" }}
                          cursor="pointer"
                          onClick={async() => {
                            if (menuSelection != "Files") {
                              setMenuSelection("Files")
                              socket.emit('files', selectedChat._id)
                            } else {
                              setMenuSelection("")
                            }
                          }}
                          justifyContent='space-between'
                          alignItems={'center'}
                          fontWeight={menuSelection === "Files" ? 'bold' : 'normal'}
                          backgroundColor={menuSelection === "Files" ? '#D0EACD' : null}
                          mt={2}
                          mb={2}
                          
                          borderRadius={10}
                          shadow='md'
                          borderWidth={1}
                          p={2}
                          pl={5}
                          pr={5}
                        >
                        
                          <Text fontWeight={'bold'} >Files</Text>
                          <Icon as={menuSelection === "Files" ? BsChevronDown : BsChevronRight}  w="20px" h="20px" />
                        
                      </Flex>
                      {menuSelection == "Files" ? 
                      <List >
                        {files.map(file => (
                          <ListItem
                          with
                          _hover={{ backgroundColor: "#F1F1F1" }}
                          cursor="pointer"
                          onClick={async() => {
                              
  
                          }}
                          mt={2}
                          mb={2}
                          >
                        <Box
                          as="a"
                          href={file.message}
                          target="_blank"
                          download
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-start"
                          p={4}
                          rounded="md"
                          borderRadius={10}
                          shadow='none'
                          borderBottomWidth={0}
                          
                          pl={5}
                          pr={5}
                        >
                        <Box as={BsFillCloudArrowDownFill} size={20} />
                          <Text ml={2}>{formatFileName(file.message)}</Text>
                        </Box>
                        </ListItem>
                        ))};
                      </List>
                      : null}

                      <Flex
                          
                          _hover={{ backgroundColor: "#D0EACD" }}
                          cursor="pointer"
                          onClick={async() => {
                            if (menuSelection != "Images") {
                              setMenuSelection("Images")
                              socket.emit('images', selectedChat._id)
                            } else {
                              setMenuSelection("")
                            }
                          }}
                          justifyContent='space-between'
                          alignItems={'center'}
                          fontWeight={menuSelection === "Images" ? 'bold' : 'normal'}
                          backgroundColor={menuSelection === "Images" ? '#D0EACD' : null}
                          mt={2}
                          mb={2}
                          
                          borderRadius={10}
                          shadow='md'
                          borderWidth={1}
                          p={2}
                          pl={5}
                          pr={5}
                        >
                        
                          <Text fontWeight={'bold'} >Images</Text>
                          <Icon as={menuSelection === "Images" ? BsChevronDown : BsChevronRight}  w="20px" h="20px" />
                        
                      </Flex>
                      {menuSelection == "Images" ? 

                      <Wrap >
                        
                        {images.map(file => (

                          <WrapItem
                          cursor="pointer"
                          onClick={async() => {
                          }}
                          
                          w={'30%'}
                          
                          
                          
                          
                          >
                        <Image
                        src={file.message}
                        
                        alt="image message"
                        fit={'contain'}
                        borderRadius={10}
                          shadow='md'
                          borderBottomWidth={1}
                        
                        />
                        </WrapItem>
                        ))}
                        
                        </Wrap>
                      
                      : null }

                      <Flex
                          
                          _hover={{ backgroundColor: "#D0EACD" }}
                          cursor="pointer"
                          onClick={async() => {
                            if (menuSelection != "Search") {
                              setMenuSelection("Search")
                            } else {
                              setMenuSelection("")
                            }
                          }}
                          justifyContent='space-between'
                          alignItems={'center'}
                          fontWeight={menuSelection === "Search" ? 'bold' : 'normal'}
                          backgroundColor={menuSelection === "Search" ? '#D0EACD' : null}
                          mt={2}
                          mb={2}
                          
                          borderRadius={10}
                          shadow='md'
                          borderWidth={1}
                          p={2}
                          pl={5}
                          pr={5}
                        >
                        
                          <Text fontWeight={'bold'} >Search</Text>
                          <Icon as={menuSelection === "Search" ? BsChevronDown : BsChevronRight}  w="20px" h="20px" />
                        
                      </Flex>

                      <>
                        <Flex
                        
                        alignItems="center"
                        minH="70px"
                        justifyContent="start"
                        //   mb="5px"
                      >
                        <Flex direction="column" align="center" h="100%" me="20px" >
                          <Icon
                            as={CgShapeCircle}
                            color="teal.300"
                            h={"30px"}
                            w={"30px"}
                            zIndex="1"
                            position="relative"
                          />
                          <Box w="2px" bg="gray.200" h="100%" minH='35px'></Box>
                        </Flex>
                        <Flex direction="column" justifyContent="flex-start" h="100%">
                          <Text fontSize="sm" color={textColor} fontWeight="bold">
                            Order received
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.400"
                            fontWeight="normal"
                            mb="14px"
                          >
                            22 DEC 7:20 AM
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" minH="70px" justifyContent="start">
                        <Flex direction="column" align="center" h="100%" me="20px">
                          <Icon
                            as={CgShapeCircle}
                            color="teal.300"
                            h={"30px"}
                            w={"30px"}
                            zIndex="1"
                            position="relative"
                          />
                          <Box w="2px" bg="gray.200" h="100%" minH='35px'></Box>
                        </Flex>
                        <Flex direction="column" justifyContent="flex-start" h="100%">
                          <Text fontSize="sm" color={textColor} fontWeight="bold">
                            Generate order id #1832412
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.400"
                            fontWeight="normal"
                            mb="14px"
                          >
                            22 DEC 7:21 AM
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex alignItems="center" minH="70px" justifyContent="start">
                        <Flex direction="column" align="center" h="100%" me="20px">
                          <Icon
                            as={CgShapeCircle}
                            color="blue.400"
                            h={"30px"}
                            w={"30px"}
                            zIndex="1"
                            position="relative"
                          />
                          <Box w="2px" bg="gray.200" h="100%" minH='35px'></Box>
                        </Flex>
                        <Flex direction="column" justifyContent="flex-start" h="100%">
                          <Text fontSize="sm" color={textColor} fontWeight="bold">
                            Order transmited to courier
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.400"
                            fontWeight="normal"
                            mb="14px"
                          >
                            22 DEC 8:10 AM
                          </Text>
                        </Flex>
                      </Flex>
                      
                      <Flex alignItems="center" minH="70px" justifyContent="start">
                        <Flex direction="column" align="center" h="100%" me="20px">
                          <Icon
                            as={CgShapeCircle}
                            color="blue.400"
                            h={"30px"}
                            w={"30px"}
                            zIndex="1"
                            position="relative"
                          />
                          <Box w="2px"  h="100%" minH='35px'></Box>
                        </Flex>
                        <Flex direction="column" justifyContent="flex-start" h="100%">
                          <Text fontSize="sm" color={textColor} fontWeight="bold">
                            Order delivered
                          </Text>
                          <Text
                            fontSize="sm"
                            color="gray.400"
                            fontWeight="normal"
                            mb="14px"
                          >
                            22 DEC 4:54 PM
                          </Text>
                        </Flex>
                      </Flex>
                      </>

                      
                </Flex>
                </>
                : null }
            </Flex>
            <Box
                backgroundColor="white"
                borderRadius="md"
                boxShadow="lg"
                position="absolute"
                top="10%"
                left="50%"
                transform="translate(-50%, 0)"
                width="90%"
                maxWidth="600px"
                p={4}
                display={isOpen ? 'block' : 'none'}
            >
        <Button variant="outline" onClick={onClose}>
          Close
        </Button>
        <Box mt={4}>
          <FormControl>
            <FormLabel htmlFor="recipient">Recipient</FormLabel>
            <Input type="text" id="recipient" placeholder="Enter recipient" onChange={(e) => setRecEmail(e.target.value)}/>
          </FormControl>
          <Button mt={4} variantColor="teal" type="submit" onClick={createChat}>
            Send
          </Button>
        </Box>
      </Box>
      
    </Box>
  );
}



export default ChatUi;
