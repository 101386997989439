import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Box, Heading,Flex, Icon, Text, useColorModeValue, useColorMode } from "@chakra-ui/react";
import React from "react";
import CheckoutForm from "./checkoutForm";

//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

const stripePromise = loadStripe("pk_live_51MXrqLEDfGoNCpuzG4DPVOWnJaoNRusee6UxEgrMIAwAiylcZYMOcXjJysOwCvl2ed4I3XAtsmr87t9vAGBLlTHb006oEEfL6v");

function PaymentPage() {
  return (
    <Box maxWidth="800px" mx="auto" mt={100}>
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </Box>
  );
}

export default PaymentPage;