/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Box,
    Flex,
    Icon,
    Grid, SimpleGrid, Stack, Text, useColorModeValue
} from "@chakra-ui/react";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import Globe from "components/Globe/Globe";
// Custom icons
import {
    CartIcon,
    DocumentIcon,
    GlobeIcon, WalletIcon
} from "components/Icons/Icons.js";
import React from "react";
import {
    barChartDataDefault,
    barChartOptionsDefault,
    lineChartDataDefault,
    lineChartOptionsDefault
} from "variables/charts";
import { salesData } from "variables/general";
import ActiveUsers from "./components/ActiveUsers";
import MiniStatistics from "./components/MiniStatistics";
import TopCandidates from "./components/TopCandidates";
import Jobs from "./components/Jobs";
import { useState, useEffect, useContext } from "react";
import io from 'socket.io-client';
import { SocketContext } from '../../components/Layout/MainPanel'
import { FaClock,FaCoins } from "react-icons/fa";
import { BsFillBriefcaseFill,BsFileTextFill } from "react-icons/bs"



export default function Default() {
  const socket = useContext(SocketContext);
  const [credits, setCredits] = useState(undefined);
  const [processedResumes, setProcessedResumes] = useState(undefined);
  const [totalJobs, setTotalJobs] = useState(undefined);
  const [timeSaved, setTimeSaved] = useState(undefined);
  const [topCandidates, setTopCandidates] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);

  useEffect(() => {
    if (socket){
    socket.emit('getCompanyCredits', {});
    socket.emit('getJobsAndResumesByCompanyId', {});
    socket.emit('getTopMatchingResumes', {});
    socket.emit('myJobsDashboard', {});

    socket.on('myJobsDashboard', (data) => {
      
      setActiveJobs(data)
    });

    socket.on('getTopMatchingResumes', (data) => {
      
      setTopCandidates(data)
    });


    socket.on('getCompanyCredits', (data) => {
      
      setCredits(data.credits)
    });

    socket.on('getJobsAndResumesByCompanyId', (data) => {
      
      setProcessedResumes(data.resumeCount)
      setTotalJobs(data.jobCount)
      let time = data.resumeCount * 3
      setTimeSaved(time)
    });
    
    }

  }, [socket]);


  // Chakra Color Mode
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
    const [lastPong, setLastPong] = useState(null);



    
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text
        color={textColor}
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
        RezGenie Dashboard
      </Text>
      <Grid
        templateColumns={{ sm: "4fr 1fr", xl: "1.2fr 1fr" }}
        gap="32px"
        maxW="100%"
        w="100%"
      >
        <Box
          minW="700px"
          h="700px"
          position="absolute"
          right="30px"
          top="14%"
          display={{ sm: "none", md: "block" }}
        >
          <Globe />
        </Box>
        <Stack
          direction="column"
          spacing="24px"
          w="100%"
          mb="24px"
          maxW={{ sm: "315px", md: "100%" }}
          zIndex="0"
        >
          <SimpleGrid columns={{ sm: "1", md: "2" }} spacing="24px">
            <MiniStatistics
              title={"Credits"}
              amount={credits}
              percentage={null}
              icon={<Icon as={FaCoins} color={'black'} h={"24px"} w={"24px"}/>}
            />
            <MiniStatistics
              title={"Time Saved"}
              amount={timeSaved ? `${(timeSaved / 60).toFixed(1)} Hours` : null}
              percentage={5}
              icon={<Icon as={FaClock} color={'black'} h={"24px"} w={"24px"}/>}
            />
            <MiniStatistics
              title={"Processed Resumes"}
              amount={processedResumes}
              percentage={-14}
              icon={
                <Icon as={BsFileTextFill} color={'black'} h={"24px"} w={"24px"}/>
              }
            />
            <MiniStatistics
              title={"Total Jobs"}
              amount={totalJobs}
              percentage={8}
              icon={<Icon as={BsFillBriefcaseFill} color={'black'} h={"24px"} w={"24px"}/>}
            />
          </SimpleGrid>
          
        </Stack>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.0fr" }}
        gap="24px"
        mb={{ lg: "26px" }}
      >
        <TopCandidates
            title={"Top Canditates"}
            labels={["Name", "Score", "Match", "Job"]}
            candidates={topCandidates}
          />
        <Jobs
            title={"Jobs"}
            labels={["Name", "Good Matches", "Processed Resumes"]}
            jobs={activeJobs}
          />
        
        
      </Grid>
    </Flex>
  );
}
